import { takeLeading, call, put, all } from 'redux-saga/effects';
import ApiService from 'services/api';
import logger from 'services/logger';

const initialState = {
  list: []
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'ARTICLE_CATEGORIES/GET_LIST_SUCCESS':
      return { ...state, list: payload.categories };

    default:
      return state;
  }
};

const getList = () => ({ type: 'ARTICLE_CATEGORIES/GET_LIST' });

function* sagas() {
  yield all([
    yield takeLeading('ARTICLE_CATEGORIES/GET_LIST', function*() {
      try {
        const { article_categories } = yield call(
          ApiService.getArticleCategories
        );

        yield put({
          type: 'ARTICLE_CATEGORIES/GET_LIST_SUCCESS',
          payload: {
            categories: article_categories
          }
        });
      } catch (error) {
        logger(error);
      }
    })
  ]);
}

export { reducer, sagas };

export default {
  getList
};
