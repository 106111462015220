const initialState = {
  isFetching: false,
  isFetched: false,
  page: 1,
  items: 16,
  total: 0,
  list: [],
  ids: [],
  isFetchingLiked: false,
  isFetchedLiked: false,
  favoriteResumes: [],
  isFetchingResumeByJob: false,
  isFetchedResumeByJob: false,
  favoriteResumesIdByJob: [],
  isFavorite: null
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case 'RESUME/FAVORITE_IDS_REQUEST':
      return {...state, isFetching: true, isFetched: false};
    case 'RESUME/FAVORITE_IDS_FAILURE':
      return {...state, isFetching: false, isFetched: false};
    case 'RESUME/FAVORITE_IDS_SUCCESS':
      return {
        ...state, isFetching: false, isFetched: true, ids: [...payload.resume_saves_ids],
      };

    case 'RESUME/FAVORITE_IDS_BY_JOB_REQUEST':
      return {...state, isFetching: true, isFetched: false};
    case 'RESUME/FAVORITE_IDS_BY_JOB_FAILURE':
      return {...state, isFetching: false, isFetched: false};
    case 'RESUME/FAVORITE_IDS_BY_JOB_SUCCESS':
      return {
        ...state, isFetching: false, isFetched: true, favoriteResumesIdByJob: [...payload.resume_saves_ids],
      };

    case 'RESUME/FAVORITE_LIST_REQUEST':
      return {...state, isFetching: true, isFetched: false};
    case 'RESUME/FAVORITE_LIST_FAILURE':
      return {...state, isFetching: false, isFetched: false};
    case 'RESUME/FAVORITE_LIST_SUCCESS':
      return {
        ...state, isFetching: false, isFetched: true, list: [...payload.jobs],
      };

    case 'RESUME/FAVORITE_LIST_BY_JOB_REQUEST':
      return {...state, isFetchingResumeByJob: true, isFetchedResumeByJob: false};
    case 'RESUME/FAVORITE_LIST_BY_JOB_FAILURE':
      return {...state, isFetchingResumeByJob: false, isFetchedResumeByJob: false};
    case 'RESUME/FAVORITE_LIST_BY_JOB_SUCCESS':
      return {
        ...state, isFetchingResumeByJob: false, isFetchedResumeByJob: true, favoriteResumes: [...payload.resumes],
      };

    case 'RESUME/FAVORITE_BY_JOB_SUCCESS':
      return {
        ...state, isFavorite: payload.is_liked,
      };

    case 'RESUME/TOGGLE_FAVORITE_REQUEST':
      return {...state, isFetchingLiked: true, isFetchedLiked: false};
    case 'RESUME/TOGGLE_FAVORITE_FAILURE':
      return {...state, isFetchingLiked: false, isFetchedLiked: false};
    case 'RESUME/TOGGLE_FAVORITE_SUCCESS':
      if (payload.liked) {
        return {
          ...state, isFetchingLiked: false, isFetchedLiked: true,
          ids: [...state.ids, payload.id],
        }
      }
      // const newIds = state.ids.filter(id => id !== payload.id);

      const newIds = state.ids.slice();
      const index = newIds.indexOf(payload.id);
      if (index > -1) {
        newIds.splice(index, 1);
      }

      const newList = state.list.filter(item => item.id !== payload.id);
      return {
        ...state, isFetchingLiked: false, isFetchedLiked: true,
        ids: [...newIds],
        list: [...newList],
      };

    case 'RESUME/RESET':
    case 'RESUME/FAVORITE_RESET':
      return initialState;
    default:
      return state;
  }
};

export default reducer;