const initialState = {
  isFetching: false,
  isFetched: false,
  page: 1,
  items: 12,
  total: 0,
  jobsList: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'JOBS/LIST_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'JOBS/LIST_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'JOBS/LIST_SUCCESS':
      return { ...state, isFetching: false, isFetched: true, page: payload.page, jobsList: [...payload.jobs], total: payload.total, };

    case 'JOBS/ITEM_DELETE_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'JOBS/ITEM_DELETE_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'JOBS/ITEM_DELETE_SUCCESS':
      const newList = state.jobsList.filter(item => item.id !== payload.id);
      return { ...state, isFetching: false, isFetched: true, jobsList: [...newList], total: state.total - 1, };

    case 'JOBS/RESET':
    case 'JOBS/CATALOG_RESET':
      return initialState;
    default:
      return state;
  };
};

export default reducer;