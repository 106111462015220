const initialState = {
  isFetching: false,
  isFetched: false,
  page: 1,
  items: 16,
  total: 0,
  list: [],
  ids: [],
  isFetchingLiked: false,
  isFetchedLiked: false,
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case 'JOBS/FAVORITE_IDS_REQUEST':
      return {...state, isFetching: true, isFetched: false};
    case 'JOBS/FAVORITE_IDS_FAILURE':
      return {...state, isFetching: false, isFetched: false};
    case 'JOBS/FAVORITE_IDS_SUCCESS':
      return {
        ...state, isFetching: false, isFetched: true, ids: [...payload.job_saves_ids],
      };

    case 'JOBS/FAVORITE_LIST_REQUEST':
      return {...state, isFetching: true, isFetched: false};
    case 'JOBS/FAVORITE_LIST_FAILURE':
      return {...state, isFetching: false, isFetched: false};
    case 'JOBS/FAVORITE_LIST_SUCCESS':
      return {
        ...state, isFetching: false, isFetched: true, list: [...payload.jobs],
      };

    case 'JOBS/TOGGLE_FAVORITE_REQUEST':
      return {...state, isFetchingLiked: true, isFetchedLiked: false};
    case 'JOBS/TOGGLE_FAVORITE_FAILURE':
      return {...state, isFetchingLiked: false, isFetchedLiked: false};
    case 'JOBS/TOGGLE_FAVORITE_SUCCESS':
      if (payload.liked) {
        return {
          ...state, isFetchingLiked: false, isFetchedLiked: true,
          ids: [...state.ids, payload.id],
        }
      }
      const newIds = state.ids.filter(id => id !== payload.id);
      const newList = state.list.filter(item => item.id !== payload.id);
      return {
        ...state, isFetchingLiked: false, isFetchedLiked: true,
        ids: [...newIds],
        list: [...newList],
      };

    case 'JOBS/RESET':
    case 'JOBS/FAVORITE_RESET':
      return initialState;
    default:
      return state;
  }
  ;
};

export default reducer;