const getJobDetailRequest = () => ({type: 'JOBS/DETAIL_REQUEST'});
const getJobDetailFailure = () => ({type: 'JOBS/DETAIL_FAILURE'});
const getJobDetailSuccess = (data) => ({type: 'JOBS/DETAIL_SUCCESS', payload: {...data}});
const getJobDetail = (id) => ({type: 'JOBS/DETAIL', payload: {id}});

const getJobsListRequest = () => ({type: 'JOBS/LIST_REQUEST'});
const getJobsListFailure = () => ({type: 'JOBS/LIST_FAILURE'});
const getJobsListSuccess = (data) => ({type: 'JOBS/LIST_SUCCESS', payload: {...data}});
const getJobsList = (page, items) => ({type: 'JOBS/LIST', payload: {page, items}});

const deleteJobRequest = () => ({type: 'JOBS/ITEM_DELETE_REQUEST'});
const deleteJobFailure = () => ({type: 'JOBS/ITEM_DELETE_FAILURE'});
const deleteJobSuccess = (id) => ({type: 'JOBS/ITEM_DELETE_SUCCESS', payload: {id}});
const deleteJob = (id) => ({type: 'JOBS/ITEM_DELETE', payload: {id}});

const saveJobRequest = () => ({type: 'JOBS/SAVE_JOB_REQUEST'});
const saveJobFailure = () => ({type: 'JOBS/SAVE_JOB_FAILURE'});
const saveJobSuccess = () => ({type: 'JOBS/SAVE_JOB_SUCCESS'});
const saveJob = (values) => ({type: 'JOBS/SAVE_JOB', payload: {...values}});

const saveTempJobRequest = () => ({type: 'JOBS/SAVE_TEMP_JOB_REQUEST'});
const saveTempJobFailure = () => ({type: 'JOBS/SAVE_TEMP_JOB_FAILURE'});
const saveTempJobSuccess = () => ({type: 'JOBS/SAVE_TEMP_JOB_SUCCESS'});
const saveTempJob = (values) => ({type: 'JOBS/SAVE_TEMP_JOB', payload: {...values}});

const resetItem = () => ({type: 'JOBS/RESET_ITEM'});

const toggleFavoriteJobRequest = () => ({type: 'JOBS/TOGGLE_FAVORITE_REQUEST'});
const toggleFavoriteJobFailure = () => ({type: 'JOBS/TOGGLE_FAVORITE_FAILURE'});
const toggleFavoriteJobSuccess = (id, liked) => ({type: 'JOBS/TOGGLE_FAVORITE_SUCCESS', payload: {id, liked}});
const toggleFavoriteJob = (values) => ({type: 'JOBS/TOGGLE_FAVORITE', payload: {...values}});

const payJobRequest = () => ({type: 'JOBS/PAY_ITEM_REQUEST'});
const payJobFailure = () => ({type: 'JOBS/PAY_ITEM_FAILURE'});
const payJobSuccess = () => ({type: 'JOBS/PAY_ITEM_SUCCESS'});
const payJob = (values) => ({type: 'JOBS/PAY_ITEM', payload: {values}});

const payJobPlanRequest = () => ({type: 'JOBS/PAY_PLAN_REQUEST'});
const payJobPlanFailure = () => ({type: 'JOBS/PAY_PLAN_FAILURE'});
const payJobPlanSuccess = () => ({type: 'JOBS/PAY_PLAN_SUCCESS'});
const payJobPlan = (values) => ({type: 'JOBS/PAY_PLAN', payload: {values}});

const getJobsCatalogRequest = () => ({type: 'JOBS/CATALOG_LIST_REQUEST'});
const getJobsCatalogFailure = () => ({type: 'JOBS/CATALOG_LIST_FAILURE'});
const getJobsCatalogSuccess = (data) => ({type: 'JOBS/CATALOG_LIST_SUCCESS', payload: {...data}});
const getJobsCatalog = (page, items, values) => ({type: 'JOBS/CATALOG_LIST', payload: {page, items, values}});

const resetCatalog = () => ({ type: 'JOBS/CATALOG_RESET' });

const getFavoriteJobsIdsRequest = () => ({type: 'JOBS/FAVORITE_IDS_REQUEST'});
const getFavoriteJobsIdsFailure = () => ({type: 'JOBS/FAVORITE_IDS_FAILURE'});
const getFavoriteJobsIdsSuccess = (data) => ({type: 'JOBS/FAVORITE_IDS_SUCCESS', payload: {...data}});
const getFavoriteJobsIds = () => ({type: 'JOBS/FAVORITE_IDS'});

const getFavoriteJobsRequest = () => ({type: 'JOBS/FAVORITE_LIST_REQUEST'});
const getFavoriteJobsFailure = () => ({type: 'JOBS/FAVORITE_LIST_FAILURE'});
const getFavoriteJobsSuccess = (data) => ({type: 'JOBS/FAVORITE_LIST_SUCCESS', payload: {...data}});
const getFavoriteJobs = (page, items) => ({type: 'JOBS/FAVORITE_LIST', payload: {page, items}});

const resetFavoriteList = () => ({ type: 'JOBS/FAVORITE_LIST_RESET' });


export default {
  getJobDetail, getJobDetailRequest, getJobDetailFailure, getJobDetailSuccess,
  getJobsList, getJobsListRequest, getJobsListFailure, getJobsListSuccess,
  deleteJob, deleteJobRequest, deleteJobFailure, deleteJobSuccess,
  saveJob, saveJobRequest, saveJobFailure, saveJobSuccess,
  saveTempJob, saveTempJobRequest, saveTempJobFailure, saveTempJobSuccess,
  resetItem,
  toggleFavoriteJob, toggleFavoriteJobRequest, toggleFavoriteJobFailure, toggleFavoriteJobSuccess,
  payJob, payJobRequest, payJobFailure, payJobSuccess,
  payJobPlan, payJobPlanRequest, payJobPlanFailure, payJobPlanSuccess,
  getJobsCatalog, getJobsCatalogRequest, getJobsCatalogFailure, getJobsCatalogSuccess, resetCatalog,
  getFavoriteJobsIds, getFavoriteJobsIdsRequest, getFavoriteJobsIdsFailure, getFavoriteJobsIdsSuccess,
  getFavoriteJobs, getFavoriteJobsRequest, getFavoriteJobsFailure, getFavoriteJobsSuccess, resetFavoriteList,
};
