import { error as noticeError } from 'react-notification-system-redux';

import store from 'store';
import logger from "services/logger";
import AuthModule from 'modules/auth';
import { transformApiValidation } from './utils';
import ROUTES from 'config/routes';
import history from 'services/history';


const initialConfig = {
  timeout: 30000,
  validateStatus: status => {
    return status;
  },
};

function handleRequest(config) {
  return config;
}

function handleResponse(response) {
  const { message } = response.data;

  // no error
  if (response.status < 400) {
    return response.data;
  }

  switch (response.status) {
    // validation error
    case 400:
      return Promise.reject({
        type: 'VALIDATION',
        data: transformApiValidation(message)
      })

    // auth error
    case 401:
      store.dispatch(AuthModule.reset());
      store.dispatch(noticeError({
        message: response.message || response.data[0].message,
      }));

      return Promise.reject({
        type: 'AUTH',
        data: response
      });
    case 404:
      history.push(ROUTES.page404);
      break;
    // user action error
    case 422:
      store.dispatch(
        noticeError({
          message
        })
      );

      return Promise.reject({
        type: 'FAIL_ACTION',
        data: message
      });

    // common error
    default:
      store.dispatch(
        noticeError({
          message
        })
      )

      return Promise.reject({
        type: 'ERROR',
        data: message || 'Something went wrong'
      });
  }
}

const handleResponseError = error => {
  logger(error);

  store.dispatch(
    noticeError({
      message: 'Something went wrong'
    })
  );

  return Promise.reject({
    type: 'ERROR',
    data: error.message
  })
}

export {
  initialConfig as default,
  handleRequest,
  handleResponse,
  handleResponseError,
}
