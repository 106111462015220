const contactUsRequest = () => {
  return { type: 'CONTACT/SEND_MESSAGE_REQUEST' };
};
const contactUsFailure = () => {
  return { type: 'CONTACT/SEND_MESSAGE_FAILURE' };
};
const contactUsSuccess = () => {
  return { type: 'CONTACT/SEND_MESSAGE_SUCCESS' };
};
const contactUs = (values) => ({ type: 'CONTACT/SEND_MESSAGE', payload: { values } });


const getInterestsRequest = () => {
  return { type: 'CONTACT/GET_INTERESTS_REQUEST' };
};
const getInterestsFailure = () => {
  return { type: 'CONTACT/GET_INTERESTS_FAILURE' };
};
const getInterestsSuccess = (data) => {
  return { type: 'CONTACT/GET_INTERESTS_SUCCESS', payload: { ...data } };
};
const getInterests = () => ({ type: 'CONTACT/GET_INTERESTS' });


export default {
  contactUsRequest, contactUsFailure, contactUsSuccess, contactUs,
  getInterestsRequest, getInterestsFailure, getInterestsSuccess, getInterests,
}