import actions from './actions';
import sagas from './sagas';


const initialState = {
  isFetching: false,
  isFetched: false,
  list: null,
  promoList: null,
  promoType: '',
  plansList: null,
  promoPlanList: null,
  prevTariffPlanDays: null,
  currentPlan: null
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'OFFERS/JOB_PRICES_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'OFFERS/JOB_PRICES_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'OFFERS/JOB_PRICES_SUCCESS':
      return {
        ...state, isFetched: true, isFetching: false, prevTariffPlanDays: payload
      }

    case 'OFFERS/PRICES_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'OFFERS/PRICES_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'OFFERS/PRICES_SUCCESS':
      if (payload.values.promocode) {
        if (payload.values.entity === "plans") {
          return {
            ...state, isFetching: false, isFetched: true, promoPlanList: [...payload.offers],
            promoType: payload.values.entity_type
          };
        } else {
          return {
            ...state, isFetching: false, isFetched: true, promoList: [...payload.offers],
            promoType: payload.values.entity_type
          };
        }
      } else if (payload.values.entity === "plans") {
        return { ...state, isFetching: false, isFetched: true, plansList: [...payload.offers] };
      } else {
        return { ...state, isFetching: false, isFetched: true, list: [...payload.offers] };
      }
    case 'OFFERS/PROMO_RESET':
      return { ...state, promoList: null, promoType: '' };

    case 'OFFERS/GET_UPGRADE_TYPE_PRICE_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'OFFERS/GET_UPGRADE_TYPE_PRICE_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'OFFERS/GET_UPGRADE_TYPE_PRICE_SUCCESS':
      if (payload && payload.values && payload.values.promocode) {
        return {
          ...state,
          isFetching: false,
          isFetched: true,
          promoList: [...payload.offers],
          currentPlan: payload.currentPlan,
          promoType: payload.values.entity_type
        };
      } else {
        return {
          ...state,
          isFetching: false,
          isFetched: true,
          currentPlan: payload.currentPlan,
          list: [...payload.offers]
        };
      }

    case 'OFFERS/RESET':
      return initialState;
    default:
      return state;
  }
};


export { reducer, sagas };
export default { ...actions };
