const initialState = {
  isFetching: false,
  isFetched: false,
  forum_categories: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FORUM/CATEGORIES_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'FORUM/CATEGORIES_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'FORUM/CATEGORIES_SUCCESS':
      return { ...state, isFetching: false, isFetched: true, forum_categories: payload.forum_categories };

    case 'FORUM/RESET':
      return initialState;
    default:
      return state;
  };
};

export default reducer;