import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import ModalModule from 'modules/modal';
import Modal from 'components/Modal';
import Button from 'components/Button';


class ModalsContainer extends Component {
  handleClose = () => {
    const {hide, onClose} = this.props;

    if (typeof onClose !== 'function') hide();
    else onClose();
  }

  render() {
    const {
      params, isOpen, children, onConfirm, confirm, isConfirmation
    } = this.props;

    return (
      <Modal
        {...this.props}
        isOpen={isOpen}
        onRequestClose={this.handleClose}
        className="modal"
      >
        <div className="modal-content">
          <div className="modal-header">
            {
              params.title &&
              <div className="modal-title">
                {params.title}
              </div>
            }
          </div>
          <div className="modal-body">
            {
              params.text &&
              <div className="modal-text">
                {params.text}
              </div>
            }
            {
              params.html &&
              <div className="modal-text">
                <div className="content-block" dangerouslySetInnerHTML={{__html: params.html}}></div>
              </div>
            }
            {
              children &&
              <div className="modal-text">
                {children}
              </div>
            }
          </div>
          {
            isConfirmation &&
            <div className="modal-footer">
              <Button
                className="modal-confirm-btn"
                onClick={onConfirm}>{params.confirm || 'Ok'}</Button>
            </div>
          }
        </div>
      </Modal>
    )
  }
}

ModalsContainer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  params: PropTypes.object,
  onConfirm: PropTypes.func,
  isConfirmation: PropTypes.bool,
}


export default connect(
  state => {
    const {isOpen, onClose, params, onConfirm, isConfirmation} = state.modal;

    return {
      isOpen,
      onClose,
      params,
      onConfirm,
      isConfirmation,
    }
  },
  {
    hide: ModalModule.hide
  }
)(ModalsContainer);