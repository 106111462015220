const ICON_NAMES = {
  add: 'add',
  agreement: 'agreement',
  arrowBack: 'arrowBack',
  arrowDropdown: 'arrowDropdown',
  check: 'check',
  close: 'close',
  closeCircle: 'closeCircle',
  delete: 'delete',
  facebook: 'facebook',
  favorite: 'favorite',
  file: 'file',
  filter: 'filter',
  hospital: 'hospital',
  instagram: 'instagram',
  language: 'language',
  linkedin: 'linkedin',
  minus: 'minus',
  more: 'more',
  password: 'password',
  pets: 'pets',
  play: 'play',
  plus: 'plus',
  radio: 'radio',
  reset: 'reset',
  search: 'search',
  selected: 'selected',
  statusCheck: 'statusCheck',
  symb: 'symb',
  tm: 'tm',
  twitter: 'twitter',
  user: 'user',
  visit: 'visit',
  youtube: 'youtube',
};

export default ICON_NAMES;