import React, { Component } from 'react';
import ReactModal from 'react-modal';
import Icon, { ICON_NAMES } from 'components/Icon';
import ModalsContainer from './ModalsContainer';


const defaults = {
  style: {
    overlay: {
      backgroundColor: null
    },
    content: {
      top: null,
      left: null,
      right: null,
      bottom: null,
      border: null,
      background: null,
      borderRadius: null,
      padding: null,
      position: null,
      overflow: null
    }
  }
};

class Modal extends Component {
  render() {
    const { onRequestClose } = this.props;

    return (
      <ReactModal
        {...defaults}
        {...this.props}>
        <button
          type="button"
          className="modal-close"
          onClick={onRequestClose}>
          <Icon
            name={ICON_NAMES.close}
            className="modal-close__icon" />
        </button>
        {this.props.children}
      </ReactModal>
    )
  }
}

export {
  Modal as default,
  ModalsContainer
}