const SHOW = 'LIGHTBOX/SHOW';
const HIDE = 'LIGHTBOX/HIDE';

const initialState = {
  isOpen: false,
  onClose: null,
  onNext: null,
  onPrev: null,
  photo: "",
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SHOW:
      return {
        isOpen: true,
        onClose: payload.onClose,
        // onNext: payload.onNext,
        // onPrev: payload.onPrev,
        photo: payload.photo,
      }
    case HIDE:
      return initialState;
    default:
      return state;
  }
}

// Actions
const show = ({ onClose, photo }) => ({ type: SHOW, payload: { onClose, photo } });

const hide = () => ({ type: HIDE });

export { reducer };
export default {
  show, hide,
};