const getCategoriesRequest = () => ({ type: 'MARKETPLACE/CATEGORIES_REQUEST' });
const getCategoriesFailure = () => ({ type: 'MARKETPLACE/CATEGORIES_FAILURE' });
const getCategoriesSuccess = (data) => ({ type: 'MARKETPLACE/CATEGORIES_SUCCESS', payload: { ...data } });
const getCategories = () => ({ type: 'MARKETPLACE/CATEGORIES' });

const getCatalogRequest = () => ({ type: 'MARKETPLACE/CATALOG_REQUEST' });
const getCatalogFailure = () => ({ type: 'MARKETPLACE/CATALOG_FAILURE' });
const getCatalogSuccess = (data) => ({ type: 'MARKETPLACE/CATALOG_SUCCESS', payload: { ...data } });
const getCatalog = (page, items, values) => ({ type: 'MARKETPLACE/CATALOG', payload: { page, items, values } });

const getFeaturedCatalogRequest = () => ({ type: 'MARKETPLACE/FEATURED_CATALOG_REQUEST' });
const getFeaturedCatalogFailure = () => ({ type: 'MARKETPLACE/FEATURED_CATALOG_FAILURE' });
const getFeaturedCatalogSuccess = (data) => ({ type: 'MARKETPLACE/FEATURED_CATALOG_SUCCESS', payload: { ...data } });
const getFeaturedCatalog = () => ({ type: 'MARKETPLACE/FEATURED_CATALOG' });

const saveItemRequest = () => ({ type: 'MARKETPLACE/SAVE_ITEM_REQUEST' });
const saveItemFailure = () => ({ type: 'MARKETPLACE/SAVE_ITEM_FAILURE' });
const saveItemSuccess = () => ({ type: 'MARKETPLACE/SAVE_ITEM_SUCCESS' });
const saveItem = (values) => ({ type: 'MARKETPLACE/SAVE_ITEM', payload: { values } });

const deleteItemRequest = () => ({ type: 'MARKETPLACE/DELETE_ITEM_REQUEST' });
const deleteItemFailure = () => ({ type: 'MARKETPLACE/DELETE_ITEM_FAILURE' });
const deleteItemSuccess = (id) => ({ type: 'MARKETPLACE/DELETE_ITEM_SUCCESS', payload: { id } });
const deleteItem = (id) => ({ type: 'MARKETPLACE/DELETE_ITEM', payload: { id } });

const getDetailItemRequest = () => ({ type: 'MARKETPLACE/DETAIL_ITEM_REQUEST' });
const getDetailItemFailure = () => ({ type: 'MARKETPLACE/DETAIL_ITEM_FAILURE' });
const getDetailItemSuccess = (data) => ({ type: 'MARKETPLACE/DETAIL_ITEM_SUCCESS', payload: { ...data } });
const getDetailItem = (id) => ({ type: 'MARKETPLACE/DETAIL_ITEM', payload: { id } });

const getProfileAdsRequest = () => ({ type: 'MARKETPLACE/PROFILE_ADS_REQUEST' });
const getProfileAdsFailure = () => ({ type: 'MARKETPLACE/PROFILE_ADS_FAILURE' });
const getProfileAdsSuccess = (data) => ({ type: 'MARKETPLACE/PROFILE_ADS_SUCCESS', payload: { ...data } });
const getProfileAds = (page, items) => ({ type: 'MARKETPLACE/PROFILE_ADS', payload: { page, items } });

const payMarketplacePostRequest = () => ({ type: 'MARKETPLACE/PAY_ITEM_REQUEST' });
const payMarketplacePostFailure = () => ({ type: 'MARKETPLACE/PAY_ITEM_FAILURE' });
const payMarketplacePostSuccess = () => ({ type: 'MARKETPLACE/PAY_ITEM_SUCCESS' });
const payMarketplacePost = (values) => ({ type: 'MARKETPLACE/PAY_ITEM', payload: { values } });

const upgradeMarketplacePostRequest = () => ({ type: 'MARKETPLACE/UPGRADE_ITEM_REQUEST' });
const upgradeMarketplacePostFailure = () => ({ type: 'MARKETPLACE/UPGRADE_ITEM_FAILURE' });
const upgradeMarketplacePostSuccess = () => ({ type: 'MARKETPLACE/UPGRADE_ITEM_SUCCESS' });
const upgradeMarketplacePost = (values) => ({ type: 'MARKETPLACE/UPGRADE_ITEM', payload: { values } });

const resetCatalog = () => ({ type: 'MARKETPLACE/RESET_CATALOG' });
const resetItem = () => ({ type: 'MARKETPLACE/RESET_ITEM' });


export default {
  getCategories, getCategoriesRequest, getCategoriesFailure, getCategoriesSuccess,
  getCatalog, getCatalogRequest, getCatalogFailure, getCatalogSuccess,
  saveItem, saveItemRequest, saveItemFailure, saveItemSuccess,
  deleteItem, deleteItemRequest, deleteItemFailure, deleteItemSuccess,
  getProfileAds, getProfileAdsRequest, getProfileAdsFailure, getProfileAdsSuccess,
  getDetailItem, getDetailItemRequest, getDetailItemFailure, getDetailItemSuccess,
  getFeaturedCatalog, getFeaturedCatalogRequest, getFeaturedCatalogFailure, getFeaturedCatalogSuccess,
  payMarketplacePost, payMarketplacePostRequest, payMarketplacePostFailure, payMarketplacePostSuccess,
  upgradeMarketplacePostRequest, upgradeMarketplacePostFailure, upgradeMarketplacePostSuccess, upgradeMarketplacePost,
  resetItem, resetCatalog,
};