import produce from 'immer';
import { takeLatest, put, all, call, select, take } from 'redux-saga/effects';

import logger from 'services/logger';
import Api from 'services/api/ApiService';

const initialState = {
  isLoading: true,
  isLoaded: false,
};

const INIT = 'APP_ENTER/INIT';
const INIT_BEGIN = 'APP_ENTER/INIT_BEGIN';
const INIT_END = 'APP_ENTER/INIT_END';


const reducer = (state = initialState, action) =>
  produce(state, draft => {
    const { type, payload } = action;

    switch (type) {
      case INIT_BEGIN:
        draft.isLoading = true;
        draft.isLoaded = false;
        break;
      case INIT_END:
        draft.isLoading = false;
        draft.isLoaded = true;
        break;
      default:
        return state;
    }
  });

const init = () => ({ type: INIT });

function* initAppEffect(action) {
  yield put({ type: INIT_BEGIN });

  try {
    const state = yield select();

    yield call(() => {
      Api.setAuthToken(state.auth.token);
    });
    if (state.auth.token) {
      yield put({ type: 'PROFILE/GET_INFO' });
    }
  } catch (error) {
    logger(error);
  } finally {
    yield put({ type: INIT_END });
  }
}

function* initAppWatcher() {
  yield takeLatest(INIT, initAppEffect);
}


export { reducer, initAppWatcher };
export default {
  init,
}