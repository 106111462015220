import actions from './actions';
import reducer from './reducer';
import sagas from './sagas';


export {
  reducer,
  sagas,
};
export default {
  ...actions
};