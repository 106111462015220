import { takeLatest, takeEvery, select, call, put, all } from 'redux-saga/effects';
import { success as noticeSuccess, error } from 'react-notification-system-redux';
import store from 'store';

import Api from 'services/api/ApiService';
import ROUTES from 'config/routes';
import logger from 'services/logger';
import history from 'services/history';
import searchActions from './actions';

function handleErrors(res) {
  if (res && res.errors) {
    throw new Error(res);
  }
  if (res && res.error) {
    throw new Error(res.error.message);
  }
}

function* searchCommonWatcher() {
  yield takeEvery('SEARCH/COMMON', searchCommonEffect);
}
function* searchCommonEffect(action) {
  try {
    yield put(searchActions.searchCommonRequest());
    const { page, items, text, slug } = action.payload;
    const response = yield call(() => {
      return Api.search(page, items, text, slug)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(searchActions.searchCommonSuccess({ ...response, page: page, text: text, slug: slug }));
  } catch (error) {
    logger(error);
    yield put(searchActions.searchCommonFailure());
  }
}


export default function* searchWatcher() {
  yield all([
    searchCommonWatcher(),
  ]);
};