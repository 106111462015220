const initialState = {
  isFetching: false,
  isFetched: false,
  info: {},
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case 'JOBS/DETAIL_REQUEST':
      return {...state, isFetching: true, isFetched: false};
    case 'JOBS/DETAIL_FAILURE':
      return {...state, isFetching: false, isFetched: false};
    case 'JOBS/DETAIL_SUCCESS':
      return {...state, isFetching: false, isFetched: true, info: {...payload.job}};

    case 'JOBS/SAVE_JOB_REQUEST':
      return {...state, isFetching: true, isFetched: false};
    case 'JOBS/SAVE_JOB_FAILURE':
      return {...state, isFetching: false, isFetched: false};
    case 'JOBS/SAVE_JOB_SUCCESS':
      return {...state, isFetching: false, isFetched: true};

    case 'JOBS/SAVE_TEMP_JOB_REQUEST':
      return {...state, isFetching: true, isFetched: false};
    case 'JOBS/SAVE_TEMP_JOB_FAILURE':
      return {...state, isFetching: false, isFetched: false};
    case 'JOBS/SAVE_TEMP_JOB_SUCCESS':
      return {...state, isFetching: false, isFetched: true};

    case 'JOBS/RESET':
    case 'JOBS/RESET_ITEM':
      return initialState;
    default:
      return state;
  }

};

export default reducer;
