const getCatalogRequest = () => ({ type: "EVENTS/CATALOG_REQUEST" });
const getCatalogFailure = () => ({ type: "EVENTS/CATALOG_FAILURE" });
const getCatalogSuccess = (data) => ({
  type: "EVENTS/CATALOG_SUCCESS",
  payload: { ...data },
});
const getCatalog = (page, items, fromDate, toDate) => ({
  type: "EVENTS/CATALOG",
  payload: { page, items, fromDate, toDate },
});

const getDetailItemRequest = () => ({ type: "EVENTS/DETAIL_ITEM_REQUEST" });
const getDetailItemFailure = () => ({ type: "EVENTS/DETAIL_ITEM_FAILURE" });
const getDetailItemSuccess = (data) => ({
  type: "EVENTS/DETAIL_ITEM_SUCCESS",
  payload: { ...data },
});
const getDetailItem = (id) => ({ type: "EVENTS/DETAIL_ITEM", payload: { id } });

const resetItem = () => ({ type: "EVENTS/RESET_ITEM" });

export default {
  getCatalog,
  getCatalogRequest,
  getCatalogFailure,
  getCatalogSuccess,
  getDetailItem,
  getDetailItemRequest,
  getDetailItemFailure,
  getDetailItemSuccess,
  resetItem,
};
