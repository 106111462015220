import { combineReducers } from 'redux';
import categories from './categories';
import catalog from './catalog';
import item from './item';
import profileAds from './profileAds';
import featured from './featured';

const reducer = combineReducers({
  categories, catalog, item, profileAds, featured,
});
export default reducer;