import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import ROUTES from 'config/routes';


const GuestRoute = ({ component: Component, ...rest }) => {
  const { isAuth } = rest;
  return (
    <Route
      {...rest}
      render={props => (
        !isAuth
          ? <Component {...props} />
          : <Redirect to={ROUTES.profileInfo} />
      )} />
  )
}

export default connect(
  state => {
    return {
      isAuth: state.auth.isAuth,
    }
  }
)(GuestRoute);