import statesList from './states';
import countriesList from './countries';

export const userTypesCaregivers = [
  {
    slug: 'family_or_friend',
    title: 'Family or Friend',
    id: 1,
  },
  {
    slug: 'professional_in_home',
    title: 'Professional In-Home',
    id: 2,
  },
  {
    slug: 'professional_in_facility',
    title: 'Professional in Long- or Short-Term Care Facility',
    id: 3,
  },
  {
    slug: 'professional_in_home_and_facility',
    title: 'Professional Who Works Both in Homes and Facilities',
    id: 4,
  },
];

export const userTypesCompanies = [
  {
    slug: 'agency',
    title: 'Agency',
    id: 5,
  },
  {
    slug: 'facility',
    title: 'Short- and Long-Term Care Facility',
    id: 6,
  },
  {
    slug: 'company',
    title: 'Company or Organization Offering Other Products or Services Relevant to the Aging and/or Their Caregivers',
    id: 7,
  },
];

export const userTypesOther = [
  {
    slug: 'self',
    title: 'Individual Looking for Care, Information, Products, and Services for Self',
    id: 8,
  },
  {
    slug: 'contributor_of_content',
    title: 'Professional Individual Contributor of Content',
    id: 9,
  }
];

export const socials = [
  { id: 1, name: "facebook" },
  { id: 2, name: "instagram" },
  { id: 3, name: "twitter" },
  { id: 4, name: "linkedin" },
  { id: 5, name: "other" },
];

export const days = [
  { id: 1, name: "monday", title: "Monday", slug: "Mon" },
  { id: 2, name: "tuesday", title: "Tuesday", slug: "Tues" },
  { id: 3, name: "wednesday", title: "Wednesday", slug: "Wed" },
  { id: 4, name: "thursday", title: "Thursday", slug: "Thurs" },
  { id: 5, name: "friday", title: "Friday", slug: "Fri" },
  { id: 6, name: "saturday", title: "Saturday", slug: "Sat" },
  { id: 7, name: "sunday", title: "Sunday", slug: "Sun" },
];

export const dailyActivity = [
  { id: 1, name: "grooming", title: "Grooming" },
  { id: 2, name: "toileting", title: "Toileting" },
  { id: 3, name: "mobility", title: "Mobility" },
  { id: 4, name: "medication_reminders", title: "Medication Reminders" },
  { id: 5, name: "meals_preparation_and_serving", title: "Meals Preparation & Serving" },
  { id: 6, name: "grocery_and_shopping", title: "Grocery & Shopping" },
  { id: 7, name: "transportation", title: "Transportation" },
  { id: 8, name: "housekeeping", title: "Housekeeping" },
  { id: 9, name: "companionship", title: "Companionship" },
];

export const medicalConditions = [
  { id: 1, name: "dementia_alzheimer", title: "Dementia/ Alzheimer’s" },
  { id: 2, name: "parkinson", title: "Parkinson’s" },
  { id: 3, name: "cancer", title: "Cancer" },
  { id: 4, name: "diabetes", title: "Diabetes" },
  { id: 5, name: "respiratory", title: "Respiratory" },
  { id: 6, name: "heart_disease", title: "Heart Disease" },
  { id: 7, name: "allergies", title: "Allergies" },
  { id: 8, name: "hearing_loss", title: "Hearing Loss" },
  { id: 9, name: "low_vision_or_blindness", title: "Low Vision or Blindness" },
  { id: 10, name: "mobility_issues", title: "Mobility Issues" },
];

export const experienceWithMedicalDevices = [
  { id: 1, name: "cane", title: "Cane" },
  { id: 2, name: "walker", title: "Walker" },
  { id: 3, name: "wheelchair", title: "Wheelchair" },
  { id: 4, name: "oxygen", title: "Oxygen" },
  { id: 5, name: "hearing_aids", title: "Hearing Aids" },
];

export const languagesSpoken = [
  { id: 1, name: "english", title: "English" },
  { id: 2, name: "spanish", title: "Spanish" },
  { id: 3, name: "portuguese", title: "Portuguese" },
  { id: 4, name: "chinese", title: "Chinese" },
];

export const professionalCredentials = [
  { id: 1, name: "home_health_aide", title: "Home Health Aide" },
  { id: 2, name: "cna", title: "CNA (NA, PCA)" },
  { id: 3, name: "lpn", title: "LPN" },
  { id: 4, name: "rn", title: "RN" },
  { id: 5, name: "other", title: "Other" },
  { id: 6, name: "none", title: "None" },
];

export const relationShipPerson = [
  { id: 1, name: "mother", title: "Mother" },
  { id: 2, name: "father", title: "Father" },
  { id: 3, name: "grandmother", title: "Grandmother" },
  { id: 4, name: "grandfather", title: "Grandfather" },
  { id: 5, name: "spouse", title: "Spouse" },
  { id: 6, name: "self", title: "Self" },
];

export const typeOfResidence = [
  { id: 1, value: "private_house", title: "Private House" },
  { id: 2, value: "condo_or_townhome", title: "Condo or Townhome" },
  { id: 3, value: "apartment", title: "Apartment" },
];

export const availableAccommodations = [
  { id: 1, name: "private_bedroom", title: "Private Bedroom" },
  { id: 2, name: "private_bath", title: "Private Bath" },
  { id: 3, name: "shared_bath", title: "Shared Bath" },
];
export const typesOfPosition = [
  { id: 1, value: "in_home", title: "In-Home" },
  { id: 2, value: "institution", title: "Institution" },
  { id: 3, value: "in_home_or_institution", title: "Either In-Home or Institution" },
];
export const specificationsOfPosition = [
  { id: 1, value: "full_time", title: "Full Time" },
  { id: 2, value: "part_time", title: "Part Time" },
  { id: 3, value: "either", title: "Either" },
];
export const livingPreference = [
  { id: 1, value: "live_in", title: "Live in" },
  { id: 2, value: "live_out", title: "Live out" },
  { id: 3, value: "either", title: "Either" },
];
export const compensation = [
  { id: 1, value: 15, title: "Maximum $15 per hour" },
  { id: 2, value: 20, title: "Maximum $20 per hour" },
  { id: 3, value: 25, title: "Maximum $25 per hour" },
  { id: 4, value: 30, title: "Maximum $30 per hour" },
  { id: 5, value: -1, title: "Negotiable" },
];
export const compensation2 = [
  { id: 1, value: 15, title: "Minimum $15 per hour" },
  { id: 2, value: 20, title: "Minimum $20 per hour" },
  { id: 3, value: 25, title: "Minimum $25 per hour" },
  { id: 4, value: 30, title: "Minimum $30 per hour" },
  { id: 5, value: -1, title: "Negotiable" },
];
export const forumStatuses = [
  { id: 1, value: "moderation", title: "Waiting for Approval" },
  { id: 2, value: "rejected", title: "rejected" },
  { id: 3, value: "awaiting_payment", title: "Awaiting Review, Payment, and Posting" },
  { id: 4, value: "published", title: "Currently Posted" },
  { id: 5, value: "expired", title: "Requires Renewal" },
  { id: 6, value: "incomplete", title: "Incomplete" },
  { id: 7, value: "awaiting_review_posting", title: "Awaiting Review and Posting" },
];
export const pets = [
  { id: 1, name: "dog", title: "Dog" },
  { id: 2, name: "cat", title: "Cat" },
];

export const aboutUsHash = [
  { label: "Note from Founder", value: "note-from-founder" },
  { label: "Our Team", value: "our-team" },
  { label: "Advisory Board", value: "advisory-board" }
];

export const geographyServedStates = () => {
  const statesCopy = [...statesList];
  statesCopy.unshift({ name: "All States", abbreviation: "All States" });

  return statesCopy.map((item) => ({
    value: item.name,
    label: item.name,
    code: item.abbreviation
  }));
}

export const geographyServedCountries = () => {
  const countriesCopy = [...countriesList];
  countriesCopy.unshift({ name: "All Countries", code: "All Countries" });

  return countriesCopy.map((item) => ({
    value: item.name,
    label: item.name,
    code: item.code
  }));
}
