const SHOW = 'MODAL/SHOW';
const HIDE = 'MODAL/HIDE';

const initialState = {
  isOpen: false,
  onClose: null,
  onConfirm: null,
  isConfirmation: false,
  params: {}
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SHOW:
      return {
        isOpen: true,
        onClose: payload.onClose,
        params: payload.params,
        onConfirm: payload.onConfirm,
        isConfirmation: payload.isConfirmation,
      }
    case HIDE:
      return initialState;
    default:
      return state;
  }
}


// Actions
const show = ({ name, onClose, params, onConfirm, isConfirmation }) => ({ type: SHOW, payload: { name, onClose, params, onConfirm, isConfirmation } });

const hide = () => ({ type: HIDE });

export {
  reducer,
}

export default {
  show,
  hide,
};

