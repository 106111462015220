import { all, fork, call, put, takeEvery } from 'redux-saga/effects';
import Api from 'services/api/ApiService';

function* fetch() {
  yield takeEvery('PAGES/FETCH', function* ({ slug }) {
    try {
      yield put({ type: 'PAGES/FETCH_REQUEST' });

      const response = yield call([Api, 'getPage'], slug);

      yield put({
        type: 'PAGES/FETCH_SUCCESS',
        payload: { slug, data: response.data }
      });
    } catch ({ message }) {
      yield put({ type: 'PAGES/FETCH_FAILURE' });
    }
  });
}

function* common() {
  yield takeEvery('PAGES/COMMON', function* () {
    try {
      yield put({ type: 'PAGES/COMMON_REQUEST' });

      const response = yield call([Api, 'commonData']);

      yield put({
        type: 'PAGES/COMMON_SUCCESS',
        payload: { common: response.data }
      });
    } catch ({ message }) {
      yield put({ type: 'PAGES/COMMON_FAILURE' });
    }
  });
}

export default function* watchSaga() {
  yield all([
    fork(fetch),
    fork(common)
  ]);
}