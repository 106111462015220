const getCatalogRequest = () => ({ type: 'FORUM/CATALOG_REQUEST' });
const getCatalogFailure = () => ({ type: 'FORUM/CATALOG_FAILURE' });
const getCatalogSuccess = (data) => ({ type: 'FORUM/CATALOG_SUCCESS', payload: { ...data } });
const getCatalog = (page, items, category_id, author) => ({ type: 'FORUM/CATALOG', payload: { page, items, category_id, author } });

const getProfileCatalogRequest = () => ({ type: 'FORUM/PROFILE_CATALOG_REQUEST' });
const getProfileCatalogFailure = () => ({ type: 'FORUM/PROFILE_CATALOG_FAILURE' });
const getProfileCatalogSuccess = (data) => ({ type: 'FORUM/PROFILE_CATALOG_SUCCESS', payload: { ...data } });
const getProfileCatalog = (page, items) => ({ type: 'FORUM/PROFILE_CATALOG', payload: { page, items } });

const getCategoriesRequest = () => ({ type: 'FORUM/CATEGORIES_REQUEST' });
const getCategoriesFailure = () => ({ type: 'FORUM/CATEGORIES_FAILURE' });
const getCategoriesSuccess = (data) => ({ type: 'FORUM/CATEGORIES_SUCCESS', payload: { ...data } });
const getCategories = () => ({ type: 'FORUM/CATEGORIES' });

const saveItemRequest = () => ({ type: 'FORUM/SAVE_ITEM_REQUEST' });
const saveItemFailure = () => ({ type: 'FORUM/SAVE_ITEM_FAILURE' });
const saveItemSuccess = () => ({ type: 'FORUM/SAVE_ITEM_SUCCESS' });
const saveItem = (values) => ({ type: 'FORUM/SAVE_ITEM', payload: { values } });

const getDetailItemRequest = () => ({ type: 'FORUM/DETAIL_ITEM_REQUEST' });
const getDetailItemFailure = () => ({ type: 'FORUM/DETAIL_ITEM_FAILURE' });
const getDetailItemSuccess = (data) => ({ type: 'FORUM/DETAIL_ITEM_SUCCESS', payload: { ...data } });
const getDetailItem = (id) => ({ type: 'FORUM/DETAIL_ITEM', payload: { id } });

const resetItem = () => ({ type: 'FORUM/RESET_ITEM' });

const payForumPostRequest = () => ({ type: 'FORUM/PAY_ITEM_REQUEST' });
const payForumPostFailure = () => ({ type: 'FORUM/PAY_ITEM_FAILURE' });
const payForumPostSuccess = () => ({ type: 'FORUM/PAY_ITEM_SUCCESS' });
const payForumPost = (values) => ({ type: 'FORUM/PAY_ITEM', payload: { values } });

const getFreePostRequest = () => ({ type: 'FORUM/GET_FREE_POST_REQUEST' });
const getFreePostFailure = () => ({ type: 'FORUM/GET_FREE_POST_FAILURE' });
const getFreePostSuccess = (data) => ({ type: 'FORUM/GET_FREE_POST_SUCCESS', payload: { ...data } });
const getFreePost = () => ({ type: 'FORUM/GET_FREE_POST' });

const resetCatalog = () => ({ type: 'FORUM/CATALOG_RESET' });


export default {
  getCatalog, getCatalogRequest, getCatalogFailure, getCatalogSuccess,
  getCategories, getCategoriesRequest, getCategoriesFailure, getCategoriesSuccess,
  saveItem, saveItemRequest, saveItemFailure, saveItemSuccess,
  getProfileCatalog, getProfileCatalogRequest, getProfileCatalogFailure, getProfileCatalogSuccess,
  getDetailItem, getDetailItemRequest, getDetailItemFailure, getDetailItemSuccess,
  resetItem, resetCatalog,
  payForumPost, payForumPostRequest, payForumPostFailure, payForumPostSuccess,
  getFreePost, getFreePostRequest, getFreePostFailure, getFreePostSuccess,
};