import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-notification-system-redux';
import { reducer as common } from 'modules/common';
import { reducer as appEnter } from 'modules/appEnter';
import { reducer as auth } from 'modules/auth';
import { reducer as contact } from 'modules/contact';
import { reducer as modal } from 'modules/modal';
import { reducer as profile } from 'modules/profile';
import { reducer as resumes } from 'modules/resumes';
import { reducer as jobs } from 'modules/jobs';
import { reducer as pages } from 'modules/pages';
import { reducer as marketplace } from 'modules/marketplace';
import { reducer as articleCategories } from 'modules/articleCategories';
import { reducer as forum } from 'modules/forum';
import { reducer as lightbox } from 'modules/lightbox';
import { reducer as events } from 'modules/events';
import { reducer as offers } from 'modules/offers';
import { reducer as search } from 'modules/search';

export default combineReducers({
  notifications,
  modal,
  common,
  contact,
  appEnter,
  auth,
  profile,
  resumes,
  jobs,
  pages,
  marketplace,
  articleCategories,
  forum,
  lightbox,
  events,
  offers,
  search,
});
