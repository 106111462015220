import { takeLatest, select, call, put, all } from 'redux-saga/effects';
import { success as noticeSuccess, error } from 'react-notification-system-redux';
import store from 'store';

import Api from 'services/api/ApiService';
import ROUTES from 'config/routes';
import logger from 'services/logger';
import history from 'services/history';
import profileActions from './actions';


function handleErrors(res) {
  if (res && res.errors) {
    throw new Error(res);
  }
  if (res && res.error) {
    throw new Error(res.error.message);
  }
}

function* getProfileInfoWatcher() {
  yield takeLatest('PROFILE/GET_INFO', getProfileInfoEffect);
}
function* getProfileInfoEffect(action) {
  try {
    yield put(profileActions.getProfileInfoRequest());
    const response = yield call(() => {
      return Api.getProfileInfo()
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(profileActions.getProfileInfoSuccess(response));
  } catch (error) {
    logger(error);
    yield put(profileActions.getProfileInfoFailure());
  }
}

function* editProfileWatcher() {
  yield takeLatest('PROFILE/EDIT_INFO', editProfileEffect);
}
function* editProfileEffect(action) {
  try {
    const { values } = action.payload;
    yield put(profileActions.editProfileRequest());
    const response = yield call(() => {
      return Api.editProfile(values)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(profileActions.editProfileSuccess(response));
    yield put(profileActions.getProfileInfo());
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
  } catch (error) {
    logger(error);
    yield put(profileActions.editProfileFailure());
  }
}

function* changePasswordWatcher() {
  yield takeLatest('PROFILE/CHANGE_PASSWORD', changePasswordEffect);
}
function* changePasswordEffect(action) {
  try {
    const { values } = action.payload;
    yield put(profileActions.changePasswordRequest());
    const response = yield call(() => {
      return Api.changePassword(values)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(profileActions.changePasswordSuccess(response));
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
  } catch (error) {
    logger(error);
    yield put(profileActions.changePasswordFailure());
  }
}

export default function* profileWatchers() {
  yield all([
    getProfileInfoWatcher(),
    editProfileWatcher(),
    changePasswordWatcher(),
  ]);
};