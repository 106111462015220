const initialState = {
  isFetching: false,
  isFetched: false,
  page: 1,
  items: 16,
  total: 0,
  list: [],
  filter: null,
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case 'MARKETPLACE/CATALOG_REQUEST':
      return {...state, isFetching: true, isFetched: false};
    case 'MARKETPLACE/CATALOG_FAILURE':
      return {...state, isFetching: false, isFetched: false};
    case 'MARKETPLACE/CATALOG_SUCCESS':
      return {
        ...state, isFetching: false, isFetched: true, page: payload.page, total: payload.total,
        list: [...payload.marketplaces], filter: {...payload.values}
      };

    case 'MARKETPLACE/RESET':
    case 'MARKETPLACE/RESET_CATALOG':
      return initialState;
    default:
      return state;
  };
};

export default reducer;