import { takeLatest, select, call, put, all } from "redux-saga/effects";
import {
  success as noticeSuccess,
  error,
} from "react-notification-system-redux";
import store from "store";

import Api from "services/api/ApiService";
import ROUTES from "config/routes";
import logger from "services/logger";
import history from "services/history";
import marketplaceActions from "./actions";

function handleErrors(res) {
  if (res && res.errors) {
    throw new Error(res);
  }
  if (res && res.error) {
    throw new Error(res.error.message);
  }
}

function* getCategoriesWatcher() {
  yield takeLatest("MARKETPLACE/CATEGORIES", getCategoriesEffect);
}

function* getCategoriesEffect(action) {
  try {
    yield put(marketplaceActions.getCategoriesRequest());
    const response = yield call(() => {
      return Api.getMarketplaceCategories().then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(marketplaceActions.getCategoriesSuccess(response));
  } catch (error) {
    logger(error);
    yield put(marketplaceActions.getCategoriesFailure());
  }
}

function* getCatalogWatcher() {
  yield takeLatest("MARKETPLACE/CATALOG", getCatalogEffect);
}

function* getCatalogEffect(action) {
  try {
    yield put(marketplaceActions.getCatalogRequest());
    const { page, items, values } = action.payload;
    const response = yield call(() => {
      return Api.getMarketplaceCatalog(page, items, values).then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(
      marketplaceActions.getCatalogSuccess({ ...response, page: page, values })
    );
  } catch (error) {
    logger(error);
    yield put(marketplaceActions.getCatalogFailure());
  }
}

function* saveProductWatcher() {
  yield takeLatest("MARKETPLACE/SAVE_ITEM", saveProductEffect);
}

function* saveProductEffect(action) {
  try {
    const { values } = action.payload;
    yield put(marketplaceActions.saveItemRequest());
    const response = yield call(() => {
      return Api.productSave(values).then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(marketplaceActions.saveItemSuccess(response));
    yield call(() => {
      store.dispatch(
        noticeSuccess({
          message: response.message,
        })
      );
    });
    const { marketplace } = response;

    if (!values.isNeedPay && !values.completion_later_mode) {
      yield call(history.push, ROUTES.profileAdverts);
      return;
    }

    if (values.completion_later_mode) {
      const title = "Thank you!";
      const text =
        'Your incomplete advertisements listing is being saved for completion. When you are ready to complete it, go to "My advertisements" (found in the drop down list under your/company name in the upper right corner), locate your listing labeled "Incomplete", select Edit, and complete your job listing. You can then save it for review later or save it and then pay to post it.';
      yield call(() =>
        history.push({
          pathname: ROUTES.thankYou,
          state: { title, text },
        })
      );
      return;
    }

    if (marketplace && marketplace.id) {
      yield call(
        history.push,
        `${ROUTES.paymentMarketplace}/${marketplace.id}`
      );
    }
  } catch (error) {
    logger(error);
    yield put(marketplaceActions.saveItemFailure());
  }
}

function* deleteProductWatcher() {
  yield takeLatest("MARKETPLACE/DELETE_ITEM", deleteProductEffect);
}

function* deleteProductEffect(action) {
  try {
    const { id } = action.payload;
    yield put(marketplaceActions.deleteItemRequest());
    const response = yield call(() => {
      return Api.productDelete(id).then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(marketplaceActions.deleteItemSuccess(id));
    yield call(() => {
      store.dispatch(
        noticeSuccess({
          message: response.message,
        })
      );
    });
    yield call(history.push, ROUTES.profileAdverts);
  } catch (error) {
    logger(error);
    yield put(marketplaceActions.deleteItemFailure());
  }
}

function* getDetailProductWatcher() {
  yield takeLatest("MARKETPLACE/DETAIL_ITEM", getDetailProductEffect);
}

function* getDetailProductEffect(action) {
  try {
    yield put(marketplaceActions.getDetailItemRequest());
    const { id } = action.payload;
    const response = yield call(() => {
      return Api.productInfo(id).then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(marketplaceActions.getDetailItemSuccess(response));
  } catch (error) {
    logger(error);
    yield put(marketplaceActions.getDetailItemFailure());
  }
}

function* getFeaturedCatalogWatcher() {
  yield takeLatest("MARKETPLACE/FEATURED_CATALOG", getFeaturedCatalogEffect);
}

function* getFeaturedCatalogEffect(action) {
  try {
    yield put(marketplaceActions.getFeaturedCatalogRequest());
    // const { page, items, values } = action.payload;
    const response = yield call(() => {
      return Api.getFeaturedMarketplaceCatalog().then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(marketplaceActions.getFeaturedCatalogSuccess({ ...response }));
  } catch (error) {
    logger(error);
    yield put(marketplaceActions.getFeaturedCatalogFailure());
  }
}

function* getProfileListWatcher() {
  yield takeLatest("MARKETPLACE/PROFILE_ADS", getProfileListEffect);
}

function* getProfileListEffect(action) {
  try {
    yield put(marketplaceActions.getProfileAdsRequest());
    const { page, items } = action.payload;
    const response = yield call(() => {
      return Api.getProfileAdvertsList(page, items).then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(
      marketplaceActions.getProfileAdsSuccess({ ...response, page: page })
    );
  } catch (error) {
    logger(error);
    yield put(marketplaceActions.getProfileAdsFailure());
  }
}

function* payMarketplacePostWatcher() {
  yield takeLatest("MARKETPLACE/PAY_ITEM", payMarketplacePostEffect);
}

function* payMarketplacePostEffect(action) {
  try {
    yield put(marketplaceActions.payMarketplacePostRequest());
    const { values } = action.payload;
    const response = yield call(() => {
      return Api.payMarketplacePost(values).then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(marketplaceActions.payMarketplacePostSuccess(response));
    const title = "Payment Confirmation";
    const text =
      values.type === "standard"
        ? "Thank you, for submitting your Standard Listing/Ad. You may view, edit, or delete it from your profile page."
        : "Thank you, for submitting Featured Ad. You may view, edit, or delete it from your profile page.";
    yield call(() =>
      history.push({
        pathname: ROUTES.thankYou,
        state: { title, text },
      })
    );
    yield call(() => {
      store.dispatch(
        noticeSuccess({
          message: response.message,
        })
      );
    });
  } catch (error) {
    logger(error);
    yield put(marketplaceActions.payMarketplacePostFailure());
  }
}

function* upgradeMarketplacePostWatcher() {
  yield takeLatest("MARKETPLACE/UPGRADE_ITEM", upgradeMarketplacePostEffect);
}

function* upgradeMarketplacePostEffect(action) {
  try {
    yield put(marketplaceActions.upgradeMarketplacePostRequest());
    const { values } = action.payload;
    const response = yield call(() => {
      return Api.upgradeMarketplacePost(values).then((res) => {
        handleErrors(res);
        return res;
      })
    });

    yield put(marketplaceActions.upgradeMarketplacePostSuccess(response));
    const title = "Payment Confirmation";
    const text =
      values.type === "standard"
        ? "Thank you, for submitting your Standard Listing/Ad. You may view, edit, or delete it from your profile page."
        : "Thank you, for submitting Featured Ad. You may view, edit, or delete it from your profile page.";
    yield call(() =>
      history.push({
        pathname: ROUTES.thankYou,
        state: { title, text },
      })
    );
    yield call(() => {
      store.dispatch(
        noticeSuccess({
          message: response.message,
        })
      );
    });
  } catch (error) {
    logger(error);
    yield put(marketplaceActions.upgradeMarketplacePostFailure());
  }
}


export default function* marketplaceWatcher() {
  yield all([
    getCategoriesWatcher(),
    getCatalogWatcher(),
    saveProductWatcher(),
    deleteProductWatcher(),
    getProfileListWatcher(),
    getDetailProductWatcher(),
    getFeaturedCatalogWatcher(),
    payMarketplacePostWatcher(),
    upgradeMarketplacePostWatcher()
  ]);
}
