const initialState = {
  isFetching: false,
  isFetched: false,
  page: 1,
  items: 10,
  total: 0,
  list: [],
  free_posts: null,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FORUM/PROFILE_CATALOG_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'FORUM/PROFILE_CATALOG_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'FORUM/PROFILE_CATALOG_SUCCESS':
      return { ...state, isFetching: false, isFetched: true, page: payload.page, total: payload.total, list: [...payload.forums] };

    case 'FORUM/GET_FREE_POST_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'FORUM/GET_FREE_POST_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'FORUM/GET_FREE_POST_SUCCESS':
      return { ...state, isFetching: false, isFetched: true, free_posts: payload.active_pss, };

    // case 'FORUM/DELETE_ITEM_REQUEST':
    //   return { ...state, isFetching: true, isFetched: false };
    // case 'FORUM/DELETE_ITEM_FAILURE':
    //   return { ...state, isFetching: false, isFetched: false };
    // case 'FORUM/DELETE_ITEM_SUCCESS':
    //   const newList = state.list.filter(item => item.id !== payload.id);
    //   return { ...state, isFetching: false, isFetched: true, list: [...newList], total: state.total - 1, };

    case 'FORUM/RESET':
    case 'FORUM/CATALOG_RESET':
      return initialState;
    default:
      return state;
  };
};

export default reducer;