import { takeLatest, call, put, all } from "redux-saga/effects";

import Api from "services/api/ApiService";
import logger from "services/logger";
import eventsActions from "./actions";

function handleErrors(res) {
  if (res && res.errors) {
    throw new Error(res);
  }
  if (res && res.error) {
    throw new Error(res.error.message);
  }
}

function* getCatalogWatcher() {
  yield takeLatest("EVENTS/CATALOG", getCatalogEffect);
}
function* getCatalogEffect(action) {
  try {
    yield put(eventsActions.getCatalogRequest());
    const { page, items, fromDate, toDate } = action.payload;
    const response = yield call(() => {
      return Api.getEventsCatalog(page, items, fromDate, toDate).then((res) => {
        handleErrors(res);
        return res;
      });
    });

    yield put(
      eventsActions.getCatalogSuccess({
        ...response,
        page: page,
      })
    );
  } catch (error) {
    logger(error);
    yield put(eventsActions.getCatalogFailure());
  }
}

function* getDetailItemWatcher() {
  yield takeLatest("EVENTS/DETAIL_ITEM", getDetailItemEffect);
}
function* getDetailItemEffect(action) {
  try {
    yield put(eventsActions.getDetailItemRequest());
    const { id } = action.payload;
    const response = yield call(() => {
      return Api.eventDetail(id).then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(eventsActions.getDetailItemSuccess(response));
  } catch (error) {
    logger(error);
    yield put(eventsActions.getDetailItemFailure());
  }
}

export default function* eventsWatcher() {
  yield all([getCatalogWatcher(), getDetailItemWatcher()]);
}
