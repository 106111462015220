import store from 'store';
import { debounce } from 'throttle-debounce';


const WINDOW_RESIZE = 'COMMON/WINDOW_RESIZE';
const WINDOW_SCROLL = 'COMMON/WINDOW_SCROLL';


const initialState = {
  windowWidth: window.innerWidth,
  windowScrollTop: window.scrollY,
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case WINDOW_RESIZE:
      return { ...state, windowWidth: payload.windowWidth };
    case WINDOW_SCROLL:
      return { ...state, windowScrollTop: payload.scrollTop };

    default:
      return state;
  }
}


// Actions
const windowResize = windowWidth => ({
  type: WINDOW_RESIZE,
  payload: { windowWidth }
});

const windowScroll = scrollTop => ({
  type: WINDOW_SCROLL,
  payload: { scrollTop }
});

// Common handlers
const handleWindowResize = event => {
  store.dispatch(windowResize(window.innerWidth));
}

const handleWindowScroll = event => {
  store.dispatch(windowScroll(window.scrollY));
}

window.addEventListener('resize', debounce(300, handleWindowResize));
window.addEventListener('scroll', handleWindowScroll);


export { reducer }