import { takeLatest, select, call, put, all } from 'redux-saga/effects';
import { success as noticeSuccess, error } from 'react-notification-system-redux';

import Api from 'services/api/ApiService';
import ROUTES from "config/routes";
import logger from 'services/logger';
import history from 'services/history';
import connectActions from './actions';


function* contactWatcher() {
  yield takeLatest('CONTACT/SEND_MESSAGE', contactEffect);
}
function* contactEffect(action) {
  try {
    yield put(connectActions.contactUsRequest());
    const { values } = action.payload;

    const response = yield call(() => {
      return Api.contactUs(values)
        .then(res => {
          if (!res.success) {
            throw new Error(res.message);
          }
          return res.data;
        });
    });
    yield put(connectActions.contactUsSuccess());
    const title = "Thank You"
    const text = "Message was sent!"
    yield call(() => history.push({
      pathname: ROUTES.thankYou,
      state: { title, text },
    }));
  } catch (error) {
    yield put(connectActions.contactUsFailure());
    logger(error);
  }
}

function* getInterestsWatcher() {
  yield takeLatest('CONTACT/GET_INTERESTS', getInterestsEffect);
}
function* getInterestsEffect(action) {
  try {
    yield put(connectActions.getInterestsRequest());
    const response = yield call(() => {
      return Api.getInterests()
        .then(res => {
          if (!res.success) {
            throw new Error(res.message);
          }
          return res;
        });
    });
    yield put(connectActions.getInterestsSuccess(response));
  } catch (error) {
    yield put(connectActions.getInterestsFailure());
    logger(error);
  }
}


export default function* contactUsWatchers() {
  yield all([
    contactWatcher(),
    getInterestsWatcher(),
  ])
};