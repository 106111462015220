import {all, call, put, takeLatest} from 'redux-saga/effects';
import {success as noticeSuccess} from 'react-notification-system-redux';
import store from 'store';

import Api from 'services/api/ApiService';
import ROUTES from 'config/routes';
import logger from 'services/logger';
import history from 'services/history';
import jobsActions from './actions';

function handleErrors(res) {
  if (res && res.errors) {
    throw new Error(res);
  }
  if (res && res.error) {
    throw new Error(res.error.message);
  }
}

function* getJobDetailWatcher() {
  yield takeLatest('JOBS/DETAIL', getJobDetailEffect);
}

function* getJobDetailEffect(action) {
  try {
    yield put(jobsActions.getJobDetailRequest());
    const {id} = action.payload;
    const response = yield call(() => {
      return Api.jobDetail(id)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(jobsActions.getJobDetailSuccess(response));
  } catch (error) {
    logger(error);
    yield put(jobsActions.getJobDetailFailure());
  }
}

function* getJobsListWatcher() {
  yield takeLatest('JOBS/LIST', getJobsListEffect);
}

function* getJobsListEffect(action) {
  try {
    yield put(jobsActions.getJobsListRequest());
    const {page, items} = action.payload;
    const response = yield call(() => {
      return Api.jobsList(page, items)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(jobsActions.getJobsListSuccess({...response, page: page}));
  } catch (error) {
    logger(error);
    yield put(jobsActions.getJobsListFailure());
  }
}

function* deleteJobWatcher() {
  yield takeLatest('JOBS/ITEM_DELETE', deleteJobEffect);
}

function* deleteJobEffect(action) {
  try {
    yield put(jobsActions.deleteJobRequest());
    const {id} = action.payload;
    const response = yield call(() => {
      return Api.jobDelete(id)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(jobsActions.deleteJobSuccess(id));
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
  } catch (error) {
    logger(error);
    yield put(jobsActions.deleteJobFailure());
  }
}

function* saveTempJobWatcher() {
  yield takeLatest('JOBS/SAVE_TEMP_JOB', saveTempJobEffect);
}

function* saveTempJobEffect(action) {
  try {
    const {id, data, contact_name, contact_number} = action.payload;
    yield put(jobsActions.saveTempJobRequest());
    const response = yield call(() => {
      return Api.saveTempJob({id, data, contact_name, contact_number})
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(jobsActions.saveTempJobSuccess(response));
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
    const title = "Thank you!";
    const text = "Your incomplete job listing is being saved for completion. When you are ready to complete it, go to \"My job listings\" (found in the drop down list under your/company name in the upper right corner), locate your listing labeled \"Incomplete\", select Edit, and complete your job listing. You can then save it for review later or save it and then pay to post it."
    yield call(() => history.push({
      pathname: ROUTES.thankYou,
      state: {title, text},
    }));
  } catch (error) {
    logger(error);
    yield put(jobsActions.saveTempJobFailure());
  }
}

function* saveJobWatcher() {
  yield takeLatest('JOBS/SAVE_JOB', saveJobEffect);
}

function* saveJobEffect(action) {
  try {
    const {id, data, contact_name, contact_number, hasPlan, hasFree, isNeedPay} = action.payload;
    yield put(jobsActions.saveJobRequest());
    const response = yield call(() => {
      return Api.saveJob({id, data, contact_name, contact_number})
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(jobsActions.saveJobSuccess(response));
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });

    if (!isNeedPay) {
      yield call(history.push, ROUTES.profileJobListing);
    } else {
      if (hasPlan && hasFree) {
        yield put(jobsActions.payJob({id, price: 0, days: 30}))
      } else {
        if (response.job && response.job.id) {
          yield call(history.push, `${ROUTES.paymentJob}/${response.job.id}`);
        } else {
          yield call(history.push, `${ROUTES.profileJobListing}`);
        }
      }
    }
  } catch (error) {
    logger(error);
    yield put(jobsActions.saveJobFailure());
  }
}

function* toggleFavoriteJobWatcher() {
  yield takeLatest('JOBS/TOGGLE_FAVORITE', toggleFavoriteJobEffect);
}

function* toggleFavoriteJobEffect(action) {
  try {
    const {id, liked} = action.payload;
    yield put(jobsActions.toggleFavoriteJobRequest());
    const response = yield call(() => {
      return Api.toggleFavoriteJob({id, liked})
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(jobsActions.toggleFavoriteJobSuccess(id, liked));
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
  } catch (error) {
    logger(error);
    yield put(jobsActions.toggleFavoriteJobFailure());
  }
}

function* payJobWatcher() {
  yield takeLatest('JOBS/PAY_ITEM', payJobEffect);
}

function* payJobEffect(action) {
  try {
    yield put(jobsActions.payJobRequest());
    const {values} = action.payload;
    const response = yield call(() => {
      return Api.payJob(values)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(jobsActions.payJobSuccess(response));
    const title = "Thank you!";
    const text = "Your job post has been successfully published to Job Listings."
    yield call(() => history.push({
      pathname: ROUTES.thankYou,
      state: {title, text},
    }));
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
  } catch (error) {
    logger(error);
    yield put(jobsActions.payJobFailure());
  }
}

function* payJobPlanWatcher() {
  yield takeLatest('JOBS/PAY_PLAN', payJobPlanEffect);
}

function* payJobPlanEffect(action) {
  try {
    yield put(jobsActions.payJobPlanRequest());
    const {values} = action.payload;
    const response = yield call(() => {
      return Api.payJobPlan(values)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(jobsActions.payJobPlanSuccess(response));
    yield put(jobsActions.payJob({id: values.id, price: 0, days: 30}));
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
  } catch (error) {
    logger(error);
    yield put(jobsActions.payJobPlanFailure());
  }
}

function* getJobsCatalogWatcher() {
  yield takeLatest('JOBS/CATALOG_LIST', getJobsCatalogEffect);
}

function* getJobsCatalogEffect(action) {
  try {
    yield put(jobsActions.getJobsCatalogRequest());
    const {page, items, values} = action.payload;
    const response = yield call(() => {
      return Api.jobCatalog(page, items, values)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(jobsActions.getJobsCatalogSuccess({...response, page: page}));
  } catch (error) {
    logger(error);
    yield put(jobsActions.getJobsCatalogFailure());
  }
}

function* getFavoriteJobsIdsWatcher() {
  yield takeLatest('JOBS/FAVORITE_IDS', getFavoriteJobsIdsEffect);
}

function* getFavoriteJobsIdsEffect() {
  try {
    yield put(jobsActions.getFavoriteJobsIdsRequest());
    const response = yield call(() => {
      return Api.getFavoriteJobsIds()
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(jobsActions.getFavoriteJobsIdsSuccess({...response}));
  } catch (error) {
    logger(error);
    yield put(jobsActions.getFavoriteJobsIdsFailure());
  }
}

function* getFavoriteJobsWatcher() {
  yield takeLatest('JOBS/FAVORITE_LIST', getFavoriteJObsEffect);
}

function* getFavoriteJObsEffect(action) {
  try {
    yield put(jobsActions.getFavoriteJobsRequest());
    const {page, items} = action.payload;
    const response = yield call(() => {
      return Api.getFavoriteJobs(page, items)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(jobsActions.getFavoriteJobsSuccess({...response}));
  } catch (error) {
    logger(error);
    yield put(jobsActions.getFavoriteJobsFailure());
  }
}


export default function* jobsWatcher() {
  yield all([
    getJobDetailWatcher(),
    getJobsListWatcher(),
    deleteJobWatcher(),
    saveTempJobWatcher(),
    saveJobWatcher(),
    toggleFavoriteJobWatcher(),
    payJobWatcher(),
    payJobPlanWatcher(),
    getJobsCatalogWatcher(),
    getFavoriteJobsIdsWatcher(),
    getFavoriteJobsWatcher(),
  ]);
};
