import {combineReducers} from 'redux';
import detail from './detail';
import available from './available';
import catalog from './catalog';
import favorite from './favorite';

const reducer = combineReducers({
  detail,
  available,
  catalog,
  favorite,
});
export default reducer;