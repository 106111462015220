import { takeLatest, select, call, put, all } from 'redux-saga/effects';
import { success as noticeSuccess, error } from 'react-notification-system-redux';
import store from 'store';

import Api from 'services/api/ApiService';
import ROUTES from 'config/routes';
import logger from 'services/logger';
import history from 'services/history';
import forumActions from './actions';

function handleErrors(res) {
  if (res && res.errors) {
    throw new Error(res);
  }
  if (res && res.error) {
    throw new Error(res.error.message);
  }
}

function* getCatalogWatcher() {
  yield takeLatest('FORUM/CATALOG', getCatalogEffect);
}
function* getCatalogEffect(action) {
  try {
    yield put(forumActions.getCatalogRequest());
    const { page, items, category_id, author } = action.payload;
    const response = yield call(() => {
      return Api.getForumCatalog(page, items, category_id, author)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(forumActions.getCatalogSuccess({ ...response, page: page, category_id: category_id, author }));
  } catch (error) {
    logger(error);
    yield put(forumActions.getCatalogFailure());
  }
}

function* getCategoriesWatcher() {
  yield takeLatest('FORUM/CATEGORIES', getCategoriesEffect);
}
function* getCategoriesEffect(action) {
  try {
    yield put(forumActions.getCategoriesRequest());
    const response = yield call(() => {
      return Api.getForumCategories()
        .then(res => {
          handleErrors(res);
          return res;
        });
    });
    yield put(forumActions.getCategoriesSuccess(response));
  } catch (error) {
    logger(error);
    yield put(forumActions.getCategoriesFailure());
  }
}

function* saveForumItemWatcher() {
  yield takeLatest('FORUM/SAVE_ITEM', saveForumItemEffect);
}
function* saveForumItemEffect(action) {
  try {
    const { values } = action.payload;
    yield put(forumActions.saveItemRequest());
    const response = yield call(() => {
      return Api.forumItemSave(values)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(forumActions.saveItemSuccess(response));
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
    const title = "Article Creation";
    const text = "Your article has been submitted for consideration. We'll inform you once approved."
    yield call(() => history.push({
      pathname: ROUTES.thankYou,
      state: { title, text },
    }));
  } catch (error) {
    logger(error);
    yield put(forumActions.saveItemFailure());
  }
}

function* getProfileListWatcher() {
  yield takeLatest('FORUM/PROFILE_CATALOG', getProfileListEffect);
}
function* getProfileListEffect(action) {
  try {
    yield put(forumActions.getProfileCatalogRequest());
    const { page, items } = action.payload;
    const response = yield call(() => {
      return Api.getProfileForumList(page, items)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(forumActions.getProfileCatalogSuccess({ ...response, page: page }));
  } catch (error) {
    logger(error);
    yield put(forumActions.getProfileCatalogFailure());
  }
}

function* getDetailItemWatcher() {
  yield takeLatest('FORUM/DETAIL_ITEM', getDetailItemEffect);
}
function* getDetailItemEffect(action) {
  try {
    yield put(forumActions.getDetailItemRequest());
    const { id } = action.payload;
    const response = yield call(() => {
      return Api.getForumItemDetialInfo(id)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(forumActions.getDetailItemSuccess(response));
  } catch (error) {
    logger(error);
    yield put(forumActions.getDetailItemFailure());
  }
}

function* payForumPostWatcher() {
  yield takeLatest('FORUM/PAY_ITEM', payForumPostEffect);
}
function* payForumPostEffect(action) {
  try {
    yield put(forumActions.payForumPostRequest());
    const { values } = action.payload;
    const response = yield call(() => {
      return Api.payForumPost(values)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(forumActions.payForumPostSuccess(response));
    const title = "Payment Confirmation";
    const text = "Thank you for your payment. Your article has been published and is now available in the WeCare4® Forum."
    yield call(() => history.push({
      pathname: ROUTES.thankYou,
      state: { title, text },
    }));
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
  } catch (error) {
    logger(error);
    yield put(forumActions.payForumPostFailure());
  }
}

function* getFreePostsPostWatcher() {
  yield takeLatest('FORUM/GET_FREE_POST', getFreePostsPostEffect);
}
function* getFreePostsPostEffect(action) {
  try {
    yield put(forumActions.getFreePostRequest());
    const response = yield call(() => {
      return Api.getFreePost()
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(forumActions.getFreePostSuccess(response));
  } catch (error) {
    logger(error);
    yield put(forumActions.getFreePostFailure());
  }
}


export default function* forumWatcher() {
  yield all([
    getCatalogWatcher(),
    getCategoriesWatcher(),
    saveForumItemWatcher(),
    getProfileListWatcher(),
    getDetailItemWatcher(),
    payForumPostWatcher(),
    getFreePostsPostWatcher(),
  ]);
};