const initialState = {
  isFetching: false,
  isFetched: false,
  common: null
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'PAGES/FETCH_REQUEST': return ({
      ...state,
      isFetching: true,
      isFetched: false,
    });

    case 'PAGES/FETCH_SUCCESS': return ({
      ...state,
      isFetching: false,
      isFetched: true,
      [payload.slug]: payload.data
    });

    case 'PAGES/FETCH_FAILURE': return ({
      ...state,
      isFetching: false,
      isFetched: false,
    });

    case 'PAGES/COMMON_SUCCESS': return({
      ...state,
      common: { ...payload.common }
    });

    default: return state;
  }
};

export default reducer;