import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { userTypesCaregivers } from 'config/constants';
import ROUTES from 'config/routes';
import ROLES from 'config/roles';


const CaregiverRoute = ({ component: Component, ...rest }) => {
  const { isAuth, role } = rest;
  return (
    <Route
      {...rest}
      render={props => (
        isAuth && userTypesCaregivers.find(item => item.slug === role && role !== ROLES.FAMILY_OR_FRIEND)
          ? <Component {...props} />
          : <Redirect to={ROUTES.profileInfo} />
      )} />
  )
}

export default connect(
  state => {
    return {
      isAuth: state.auth.isAuth,
      role: state.auth.role,
    }
  }
)(CaregiverRoute);