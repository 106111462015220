const initialState = {
  isFetching: false,
  isFetched: false,
  page: 1,
  items: 16,
  total: 0,
  list: [],
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case 'JOBS/CATALOG_LIST_REQUEST':
      return {...state, isFetching: true, isFetched: false};
    case 'JOBS/CATALOG_LIST_FAILURE':
      return {...state, isFetching: false, isFetched: false};
    case 'JOBS/CATALOG_LIST_SUCCESS':
      return {
        ...state, isFetching: false, isFetched: true, page: payload.page, list: [...payload.jobs], total: payload.total,
      };

    case 'JOBS/RESET':
    case 'JOBS/CATALOG_RESET':
      return initialState;
    default:
      return state;
  };
};

export default reducer;