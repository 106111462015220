import axios from "axios";
import { objectToFormData } from "object-to-formdata";

import { isDevMode } from "config";
import store from "store";
import config, {
  handleRequest,
  handleResponse,
  handleResponseError,
} from "./initialConfig";

const api = axios.create({
  ...config,
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(handleRequest);

api.interceptors.response.use(handleResponse, handleResponseError);

class ApiService {
  // contact us
  contactUs(sendData) {
    return api.post("/feedback", { ...sendData });
  }
  getInterests() {
    return api.get("/feedback/interests");
  }

  // auth
  setAuthToken(token) {
    api.defaults.headers.common["Authorization"] = token
      ? `Bearer ${token}`
      : "";
  }
  logOut() {
    return api.get("/account/logout");
  }
  signIn(sendData) {
    return api.post("/account/sign-in", { ...sendData });
  }
  signUp(sendData) {
    return api.post("/account/sign-up", { ...sendData });
  }
  signUpPages() {
    return api.get("/pages/sign-up");
  }
  signUpPagesSlug(slug) {
    return api.get(`/pages/${slug}`);
  }

  forgotPassword(sendData) {
    return api.post("/account/password-reset-request", { ...sendData });
  }
  restorePassword(sendData) {
    return api.post("/account/password-reset-confirm", { ...sendData });
  }
  confirmResend(email) {
    return api.post("/account/confirm-email-resend", { email });
  }

  // profile
  getProfileInfo() {
    return api.get("/account/info");
  }
  editProfile(sendData) {
    return api.patch("/account/edit", { ...sendData });
  }
  changePassword(sendData) {
    return api.post("/account/password-change", { ...sendData });
  }
  jobsList(page, items) {
    return api.get(`/account/jobs?page=${page}&items=${items}`);
  }
  resumeAvailable() {
    return api.get("/account/resume");
  }

  // resume
  saveResume(values) {
    return api.post("/resume/save", objectToFormData(values));
  }
  resumeDetail(id) {
    return api.get(`/resume/info?id=${id}`);
  }
  resumeHide(hidden) {
    return api.post("/resume/hide", { hidden });
  }
  resumeDelete() {
    return api.delete("/resume/delete");
  }
  toggleFavoriteResume(values) {
    return api.post("/resume/toggle-saved", { ...values });
  }
  resumeRenew() {
    return api.post("/resume/renew");
  }
  resumeCatalog(page, items, job_id, values) {
    let request = "";
    if (values) {
      const keys = Object.keys(values);
      keys.forEach((key) => {
        if (values[key]) {
          request = `${request}&${key}=${values[key]}`;
        }
      });
    }
    return api.get(
      `/resume/catalog?page=${page}&items=${items}&job_id=${job_id}${request}`
    );
  }
  getFavoriteResumes(page, items) {
    return api.get(`/account/resume-saves?page=${page}&items=${items}`);
  }
  getFavoriteResumesByJob(page, items, job_id) {
    return api.get(
      `/account/resume-saves-by-job?page=${page}&items=${items}&job_id=${job_id}`
    );
  }
  getFavoriteResumesIds() {
    return api.get("/account/resume-saves/ids");
  }
  getFavoriteResumesIdsByJob(job_id) {
    return api.get(`/account/resume-saves-by-job/ids?job_id=${job_id}`);
  }
  getFavoriteResumesStatusByJob(data) {
    return api.post("/resume/like-status", data);
  }

  // job
  saveTempJob(values) {
    const { id, data, ...rest } = values;
    if (id) {
      return api.post("/job/save-for-completion-later", {
        id: id,
        data: { ...data },
        ...rest,
      });
    }
    return api.post("/job/save-for-completion-later", {
      data: { ...data },
      ...rest,
    });
  }
  saveJob(values) {
    const { id, data, ...rest } = values;
    if (id) {
      return api.post("/job/save", { id: id, data: { ...data }, ...rest });
    }
    return api.post("/job/save", { data: { ...data }, ...rest });
  }
  jobDetail(id) {
    return api.get(`/job/info?id=${id}`);
  }
  jobDelete(id) {
    return api.delete("/job/delete", { data: { id } });
  }
  toggleFavoriteJob(values) {
    return api.post("/job/toggle-saved", { ...values });
  }
  payJob(values) {
    return api.post("/job/promote", { ...values });
  }
  payJobPlan(values) {
    return api.post("/plan/buy", { ...values });
  }
  jobCatalog(page, items, values) {
    let request = "";
    if (values) {
      const keys = Object.keys(values);
      keys.forEach((key) => {
        if (values[key]) {
          request = `${request}&${key}=${values[key]}`;
        }
      });
    }
    return api.get(`/job/catalog?page=${page}&items=${items}${request}`);
  }
  getFavoriteJobs(page, items) {
    return api.get(`/account/job-saves?page=${page}&items=${items}`);
  }
  getFavoriteJobsIds() {
    return api.get("/account/job-saves/ids");
  }

  // static pages
  getPage(slug) {
    return api.get(`/pages/${slug}`);
  }
  commonData() {
    return api.get("/pages/common_data");
  }

  // marketplace
  getMarketplaceCategories() {
    return api.get("/marketplace/categories");
  }
  getMarketplaceCatalog(page, items, values) {
    let request = "";
    if (values) {
      const keys = Object.keys(values);
      keys.forEach((key) => {
        if (values[key]) {
          request = `${request}&${key}=${values[key]}`;
        }
      });
    }
    return api.get(
      `/marketplace/catalog?type=standard&page=${page}&items=${items}${request}`
    );
  }
  getFeaturedMarketplaceCatalog() {
    return api.get(`/marketplace/catalog?type=featured&page=1&items=100`);
  }
  productSave(values) {
    return api.post("/marketplace/save", objectToFormData(values));
  }
  productDelete(id) {
    return api.delete("/marketplace/delete", { data: { id } });
  }
  productInfo(id) {
    return api.get(`/marketplace/info?id=${id}`);
  }
  getProfileAdvertsList(page, items) {
    return api.get(`/account/marketplaces?page=${page}&items=${items}`);
  }
  payMarketplacePost(values) {
    return api.post("/marketplace/promote", { ...values });
  }
  upgradeMarketplacePost(values) {
    return api.post("/marketplace/upgrade-type", { ...values });
  }

  // offers
  getOfferPrices(values) {
    let request = "";
    if (values) {
      if (values.entity) {
        request =
          request === ""
            ? request + `?entity=${values.entity}`
            : request + `&entity=${values.entity}`;
      }
      if (values.promocode) {
        request =
          request === ""
            ? request + `?promocode=${values.promocode}`
            : request + `&promocode=${values.promocode}`;
      }
      if (values.entity_type) {
        request =
          request === ""
            ? request + `?entity_type=${values.entity_type}`
            : request + `&entity_type=${values.entity_type}`;
      }
    }

    return api.get(`/offer/list${request}`);
  }
  getJobPlans() {
    return api.get(`/job/plan`);
  }
  getUpgradePrice(values) {
    let request = "";
    if (values) {
      if (values.id) {
        request =
          request === ""
            ? request + `?id=${values.id}`
            : request + `&id=${values.id}`;
      }
      if (values.promocode) {
        request =
          request === ""
            ? request + `?promocode=${values.promocode}`
            : request + `&promocode=${values.promocode}`;
      }
      if (values.entity_type) {
        request =
          request === ""
            ? request + `?entity_type=${values.entity_type}`
            : request + `&entity_type=${values.entity_type}`;
      }
    }

    return api.get(`/marketplace/get-upgrade-type-price${request}`);
  }

  // forum
  getForumCatalog(page, items, category_id, author) {
    let request = "";
    if (category_id) {
      request += `&category_id=${category_id}`;
    }
    if (author) {
      request += `&author=${author}`;
    }
    return api.get(`/forum/catalog?page=${page}&items=${items}${request}`);
  }
  getProfileForumList(page, items) {
    return api.get(`/account/forums?page=${page}&items=${items}`);
  }
  getForumCategories() {
    return api.get("/forum/categories");
  }
  forumItemSave(values) {
    return api.post("/forum/save", { ...values });
  }
  getForumItemDetialInfo(id) {
    return api.get(`/forum/info?id=${id}`);
  }
  payForumPost(values) {
    return api.post("/forum/promote", { ...values });
  }
  getFreePost() {
    return api.get("/forum/free-pss-posts-left");
  }

  // articles
  getArticles(params = {}) {
    const { page = 1, items = 16, categoryId } = params;

    return api.get("/article/catalog", {
      params: {
        page,
        // items,
        article_category_id: categoryId,
      },
    });
  }
  getArticle(id) {
    return api.get("/article/info", { params: { id } });
  }
  getArticleCategories() {
    return api.get("/article/categories");
  }

  // events
  getEventsCatalog(page, items, fromDate, toDate) {
    let request = "";

    if (fromDate && toDate) {
      request += `&from_date=${fromDate}&to_date=${toDate}`;
    }
    return api.get(`/event/catalog?page=${page}&items=${items}${request}`);
  }
  eventDetail(id) {
    return api.get(`/event/info?id=${id}`);
  }

  // search
  search(page, items, text, slug) {
    return api.get(`/search/${slug}?page=${page}&items=${items}&text=${text}`);
  }
}

export default new ApiService();
