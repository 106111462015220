export default {
  FAMILY_OR_FRIEND: 'family_or_friend',
  PROFESSIONAL_IN_HOME: 'professional_in_home',
  PROFESSIONAL_IN_FACILITY: 'professional_in_facility',
  PROFESSIONAL_IN_HOME_AND_FACILITY: 'professional_in_home_and_facility',
  AGENCY: 'agency',
  FACILITY: 'facility',
  COMPANY: 'company',
  SELF: 'self',
  CONTRIBUTOR_OF_CONTENT: 'contributor_of_content',
};