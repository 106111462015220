const initialState = {
  isFetching: false,
  isFetched: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'JOBS/PAY_PLAN_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'JOBS/PAY_PLAN_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'JOBS/PAY_PLAN_SUCCESS':
      return { ...state, isFetching: false, isFetched: true };

    case 'JOBS/PAY_ITEM_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'JOBS/PAY_ITEM_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'JOBS/PAY_ITEM_SUCCESS':
      return { ...state, isFetching: false, isFetched: true };

    case 'JOBS/RESET':
      return initialState;
    default:
      return state;
  };
};

export default reducer;