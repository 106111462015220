const initialState = {
  isFetching: false,
  isFetched: false,
  info: {},
  isFetchingLiked: false,
  isFetchedLiked: false,
};

const reducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case 'RESUME/DETAIL_REQUEST':
      return {...state, isFetching: true, isFetched: false};
    case 'RESUME/DETAIL_FAILURE':
      return {...state, isFetching: false, isFetched: false};
    case 'RESUME/DETAIL_SUCCESS':
      return {...state, isFetching: false, isFetched: true, info: {...payload.resume}};

    case 'RESUME/RENEW_REQUEST':
      return {...state, isFetching: true, isFetched: false};
    case 'RESUME/RENEW_FAILURE':
      return {...state, isFetching: false, isFetched: false};
    case 'RESUME/RENEW_SUCCESS':
      return {...state, isFetching: false, isFetched: true};

    case 'RESUME/RESET':
    case 'RESUME/RESET_ITEM':
      return initialState;
    default:
      return state;
  }
};

export default reducer;