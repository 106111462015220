import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import preloader from 'HOC/preloader';
// import styles from './Button.module.scss';


const Container = props => {
  if (props.href)
    return (
      <a
        {...props}
        target='_blank'
        rel='noopener noreferrer'>{props.children}</a>
    )

  if (props.to)
    return (
      <Link {...props}>
      </Link>
    )

  return (
    <button
      {...props}
      type={props.type || 'button'}>
    </button>
  )
}

class Button extends Component {
  render() {
    const {
      className, href, to, disabled, type, onClick,
      children, size, bgColor, width,
      preloader, preloaderContainerStyle,
    } = this.props;
    const isDisabled = disabled || preloader;

    const attr = { className, href, to, type, disabled: isDisabled, onClick };

    return (
      <Container
        {...attr}
        style={preloader ? preloaderContainerStyle : null}
        className={classNames(`btn ${className} `, {
          [`btn--${bgColor}`]: bgColor != null,
          [`btn--width-${width}`]: width != null,
        })}
        >
        {children}
        {preloader}
      </Container>
    )
  }
}

Button.propTypes = {
  children: PropTypes.any,
  bgColor: PropTypes.oneOf(['green', 'blue', 'white', 'blue-light', 'transparent', 'bordered-transparent']),
  icon: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showLoader: PropTypes.bool,
  width: PropTypes.oneOf(['100', 'auto', 'min']),
  onClick: PropTypes.func,
};

Button.defaultProps = {
  className: '',
  disabled: false,
  // size: 'sm',
  bgColor: 'blue'
};

export default preloader(Button);