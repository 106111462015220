import { combineReducers } from 'redux';
import detail from './detail';
import profileList from './profileList';
import payment from './payment';
import catalog from './catalog';
import favorite from './favorite';

const reducer = combineReducers({
  detail, profileList, payment, catalog, favorite,
});
export default reducer;