import { all, fork } from 'redux-saga/effects';

import { initAppWatcher } from 'modules/appEnter';
import { sagas as authSagas } from 'modules/auth';
import { sagas as contactSagas } from 'modules/contact';
import { sagas as profileSagas } from 'modules/profile';
import { sagas as resumesSagas } from 'modules/resumes';
import { sagas as jobsSagas } from 'modules/jobs';
import { sagas as pagesSagas } from 'modules/pages';
import { sagas as marketplaceSagas } from 'modules/marketplace';
import { sagas as articleCategories } from 'modules/articleCategories';
import { sagas as forumSagas } from 'modules/forum';
import { sagas as eventsSagas } from 'modules/events';
import { sagas as offersSagas } from 'modules/offers';
import { sagas as searchSagas } from 'modules/search';


export default function* rootSaga() {
  yield all([
    fork(initAppWatcher),
    fork(authSagas),
    fork(contactSagas),
    fork(profileSagas),
    fork(resumesSagas),
    fork(jobsSagas),
    fork(pagesSagas),
    fork(marketplaceSagas),
    fork(articleCategories),
    fork(forumSagas),
    fork(eventsSagas),
    fork(offersSagas),
    fork(searchSagas),
  ]);
}