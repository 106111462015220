const initialState = {
  isFetching: false,
  isFetched: false,
  userData: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'PROFILE/GET_INFO_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'PROFILE/GET_INFO_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'PROFILE/GET_INFO_SUCCESS':
      return { ...state, isFetching: false, isFetched: true, userData: { ...payload.user } };

    case 'PROFILE/EDIT_INFO_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'PROFILE/EDIT_INFO_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'PROFILE/EDIT_INFO_SUCCESS':
      return { ...state, isFetching: false, isFetched: true };

    case 'PROFILE/CHANGE_PASSWORD_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'PROFILE/CHANGE_PASSWORD_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'PROFILE/CHANGE_PASSWORD_SUCCESS':
      return { ...state, isFetching: false, isFetched: true };

    case 'PROFILE/RESET':
      return initialState;

    default:
      return state;
  }
};

export default reducer;