const getResumeDetailRequest = () => ({type: 'RESUME/DETAIL_REQUEST'});
const getResumeDetailFailure = () => ({type: 'RESUME/DETAIL_FAILURE'});
const getResumeDetailSuccess = (data) => ({type: 'RESUME/DETAIL_SUCCESS', payload: {...data}});
const getResumeDetail = (id) => ({type: 'RESUME/DETAIL', payload: {id}});

const getResumeAvailableRequest = () => ({type: 'RESUME/AVAILABLE_REQUEST'});
const getResumeAvailableFailure = () => ({type: 'RESUME/AVAILABLE_FAILURE'});
const getResumeAvailableSuccess = (data) => ({type: 'RESUME/AVAILABLE_SUCCESS', payload: {...data}});
const getResumeAvailable = () => ({type: 'RESUME/AVAILABLE'});

const deleteResumeAvailableRequest = () => ({type: 'RESUME/AVAILABLE_DELETE_REQUEST'});
const deleteResumeAvailableFailure = () => ({type: 'RESUME/AVAILABLE_DELETE_FAILURE'});
const deleteResumeAvailableSuccess = () => ({type: 'RESUME/AVAILABLE_DELETE_SUCCESS'});
const deleteResumeAvailable = () => ({type: 'RESUME/AVAILABLE_DELETE'});

const hideResumeAvailableRequest = () => ({type: 'RESUME/AVAILABLE_HIDE_REQUEST'});
const hideResumeAvailableFailure = () => ({type: 'RESUME/AVAILABLE_HIDE_FAILURE'});
const hideResumeAvailableSuccess = (hidden) => ({type: 'RESUME/AVAILABLE_HIDE_SUCCESS', payload: {hidden}});
const hideResumeAvailable = (hidden) => ({type: 'RESUME/AVAILABLE_HIDE', payload: {hidden}});

const saveResumeRequest = () => ({type: 'RESUME/SAVE_RESUME_REQUEST'});
const saveResumeFailure = () => ({type: 'RESUME/SAVE_RESUME_FAILURE'});
const saveResumeSuccess = () => ({type: 'RESUME/SAVE_RESUME_SUCCESS'});
const saveResume = (values) => ({type: 'RESUME/SAVE_RESUME', payload: {...values}});

const resetItem = () => ({type: 'RESUME/RESET_ITEM'});

const toggleFavoriteResumeRequest = () => ({type: 'RESUME/TOGGLE_FAVORITE_REQUEST'});
const toggleFavoriteResumeFailure = () => ({type: 'RESUME/TOGGLE_FAVORITE_FAILURE'});
const toggleFavoriteResumeSuccess = (id, liked) => ({type: 'RESUME/TOGGLE_FAVORITE_SUCCESS', payload: {id, liked}});
const toggleFavoriteResume = (values) => ({type: 'RESUME/TOGGLE_FAVORITE', payload: {...values}});

const renewResumeRequest = () => ({type: 'RESUME/RENEW_REQUEST'});
const renewResumeFailure = () => ({type: 'RESUME/RENEW_FAILURE'});
const renewResumeSuccess = () => ({type: 'RESUME/RENEW_SUCCESS'});
const renewResume = () => ({type: 'RESUME/RENEW'});

const getResumesCatalogRequest = () => ({type: 'RESUME/CATALOG_LIST_REQUEST'});
const getResumesCatalogFailure = () => ({type: 'RESUME/CATALOG_LIST_FAILURE'});
const getResumesCatalogSuccess = (data) => ({type: 'RESUME/CATALOG_LIST_SUCCESS', payload: {...data}});
const getResumesCatalog = (page, items, job_id, values) => ({
  type: 'RESUME/CATALOG_LIST',
  payload: {page, items, job_id, values}
});

const resetCatalog = () => ({type: 'RESUME/CATALOG_RESET'});

const getFavoriteResumesIdsRequest = () => ({type: 'RESUME/FAVORITE_IDS_REQUEST'});
const getFavoriteResumesIdsFailure = () => ({type: 'RESUME/FAVORITE_IDS_FAILURE'});
const getFavoriteResumesIdsSuccess = (data) => ({type: 'RESUME/FAVORITE_IDS_SUCCESS', payload: {...data}});
const getFavoriteResumesIds = () => ({type: 'RESUME/FAVORITE_IDS'});

const getFavoriteResumesIdsByJobRequest = () => ({type: 'RESUME/FAVORITE_IDS_BY_JOB_REQUEST'});
const getFavoriteResumesIdsByJobFailure = () => ({type: 'RESUME/FAVORITE_IDS_BY_JOB_FAILURE'});
const getFavoriteResumesIdsByJobSuccess = (data) => ({type: 'RESUME/FAVORITE_IDS_BY_JOB_SUCCESS', payload: {...data}});
const getFavoriteResumesIdsByJob = (job_id) => ({type: 'RESUME/FAVORITE_IDS_BY_JOB', payload: {job_id}});

const getFavoriteResumesRequest = () => ({type: 'RESUME/FAVORITE_LIST_REQUEST'});
const getFavoriteResumesFailure = () => ({type: 'RESUME/FAVORITE_LIST_FAILURE'});
const getFavoriteResumesSuccess = (data) => ({type: 'RESUME/FAVORITE_LIST_SUCCESS', payload: {...data}});
const getFavoriteResumes = (page, items) => ({type: 'RESUME/FAVORITE_LIST', payload: {page, items}});

const getFavoriteResumesByJobRequest = () => ({type: 'RESUME/FAVORITE_LIST_BY_JOB_REQUEST'});
const getFavoriteResumesJobFailure = () => ({type: 'RESUME/FAVORITE_LIST_BY_JOB_FAILURE'});
const getFavoriteResumesJobSuccess = (data) => ({type: 'RESUME/FAVORITE_LIST_BY_JOB_SUCCESS', payload: {...data}});
const getFavoriteResumesJob = (page, items, job_id) => ({
  type: 'RESUME/FAVORITE_LIST_BY_JOB',
  payload: {page, items, job_id}
});


const getFavoriteByJobSuccess = (data) => ({type: 'RESUME/FAVORITE_BY_JOB_SUCCESS', payload: {...data}});
const getFavoriteByJob = (job_id, resume_id) => ({
  type: 'RESUME/FAVORITE_BY_JOB',
  payload: {job_id, resume_id}
});

const resetFavoriteList = () => ({type: 'RESUME/FAVORITE_LIST_RESET'});

const resetFavoriteListByJob = () => ({type: 'RESUME/FAVORITE_LIST_BY_JOB_RESET'});


export default {
  getResumeDetail,
  getResumeDetailRequest,
  getResumeDetailFailure,
  getResumeDetailSuccess,
  getResumeAvailable,
  getResumeAvailableRequest,
  getResumeAvailableFailure,
  getResumeAvailableSuccess,
  deleteResumeAvailable,
  deleteResumeAvailableRequest,
  deleteResumeAvailableFailure,
  deleteResumeAvailableSuccess,
  hideResumeAvailable,
  hideResumeAvailableRequest,
  hideResumeAvailableFailure,
  hideResumeAvailableSuccess,
  saveResume,
  saveResumeRequest,
  saveResumeFailure,
  saveResumeSuccess,
  toggleFavoriteResume,
  toggleFavoriteResumeRequest,
  toggleFavoriteResumeFailure,
  toggleFavoriteResumeSuccess,
  resetItem,
  renewResume,
  renewResumeRequest,
  renewResumeFailure,
  renewResumeSuccess,
  getResumesCatalog,
  getResumesCatalogRequest,
  getResumesCatalogFailure,
  getResumesCatalogSuccess,
  resetCatalog,
  getFavoriteResumesIds,
  getFavoriteResumesIdsRequest,
  getFavoriteResumesIdsFailure,
  getFavoriteResumesIdsSuccess,
  getFavoriteResumes,
  getFavoriteResumesRequest,
  getFavoriteResumesFailure,
  getFavoriteResumesSuccess,
  resetFavoriteList,
  getFavoriteResumesByJobRequest,
  getFavoriteResumesJobFailure,
  getFavoriteResumesJobSuccess,
  getFavoriteResumesJob,
  resetFavoriteListByJob,
  getFavoriteResumesIdsByJobRequest,
  getFavoriteResumesIdsByJobFailure,
  getFavoriteResumesIdsByJobSuccess,
  getFavoriteResumesIdsByJob,
  getFavoriteByJobSuccess,
  getFavoriteByJob
};