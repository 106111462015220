const initUser = (token) => ({ type: "AUTH/INIT_USER", payload: { token } });
const reset = () => ({ type: "AUTH/RESET" });

const logoutRequest = () => ({ type: "AUTH/LOGOUT_REQUEST" });
const logoutSuccess = () => ({ type: "AUTH/LOGOUT_SUCCESS" });
const logoutFailure = () => ({ type: "AUTH/LOGOUT_FAILURE" });
const logOut = () => ({ type: "AUTH/LOGOUT" });

const signInRequest = () => ({ type: "AUTH/SIGN_IN_REQUEST" });
const signInSuccess = (data) => ({
  type: "AUTH/SIGN_IN_SUCCESS",
  payload: { ...data },
});
const signInFailure = () => ({ type: "AUTH/SIGN_IN_FAILURE" });
const signIn = (data) => ({ type: "AUTH/SIGN_IN", payload: { ...data } });

const signUpRequest = () => ({ type: "AUTH/SIGN_UP_REQUEST" });
const signUpSuccess = (data) => ({
  type: "AUTH/SIGN_UP_SUCCESS",
  payload: { ...data },
});
const signUpFailure = () => ({ type: "AUTH/SIGN_UP_FAILURE" });
const signUp = (data) => ({ type: "AUTH/SIGN_UP", payload: { ...data } });

const signUpPagesRequest = () => ({ type: "AUTH/SIGN_UP_PAGES_REQUEST" });
const signUpPagesSuccess = (data) => ({
  type: "AUTH/SIGN_UP_PAGES_SUCCESS",
  payload: { ...data },
});
const signUpPagesFailure = (data) => ({
  type: "AUTH/SIGN_UP_PAGES_FAILURE",
  payload: { ...data },
});
const signUpPages = () => ({ type: "AUTH/SIGN_UP_PAGES" });

const signUpPagesSlugRequest = () => ({
  type: "AUTH/SIGN_UP_PAGES_SLUG_REQUEST",
});
const signUpPagesSlugSuccess = (data) => ({
  type: "AUTH/SIGN_UP_PAGES_SLUG_SUCCESS",
  payload: { ...data },
});
const signUpPagesSlugFailure = (data) => ({
  type: "AUTH/SIGN_UP_PAGES_SLUG_FAILURE",
  payload: { ...data },
});
const signUpPagesSlug = (params) => ({
  type: "AUTH/SIGN_UP_PAGES_SLUG",
  params,
});

const forgotPassword = (data) => ({
  type: "AUTH/FORGOT_PASSWORD",
  payload: { ...data },
});

const restorePasswordRequest = () => ({
  type: "AUTH/RESTORE_PASSWORD_REQUEST",
});
const restorePasswordSuccess = (data) => ({
  type: "AUTH/RESTORE_PASSWORD_SUCCESS",
  payload: { ...data },
});
const restorePasswordFailure = () => ({
  type: "AUTH/RESTORE_PASSWORD_FAILURE",
});
const restorePassword = (data) => ({
  type: "AUTH/RESTORE_PASSWORD",
  payload: { ...data },
});

const confirmResend = (email) => ({
  type: "AUTH/CONFIRM_RESEND",
  payload: { email },
});

export default {
  initUser,
  reset,
  logOut,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  signIn,
  signInRequest,
  signInSuccess,
  signInFailure,
  signUp,
  signUpRequest,
  signUpSuccess,
  signUpFailure,
  forgotPassword,
  restorePassword,
  restorePasswordRequest,
  restorePasswordSuccess,
  restorePasswordFailure,
  confirmResend,
  signUpPagesRequest,
  signUpPagesSuccess,
  signUpPagesFailure,
  signUpPages,
  signUpPagesSlugRequest,
  signUpPagesSlugSuccess,
  signUpPagesSlugFailure,
  signUpPagesSlug,
};
