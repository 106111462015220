const getPricesRequest = () => ({ type: 'OFFERS/PRICES_REQUEST' });
const getPricesFailure = () => ({ type: 'OFFERS/PRICES_FAILURE' });
const getPricesSuccess = (data) => ({ type: 'OFFERS/PRICES_SUCCESS', payload: { ...data } });
const getPrices = (values) => ({ type: 'OFFERS/PRICES', payload: { values } });

const getJobPricesRequest = () => ({ type: 'OFFERS/JOB_PRICES_REQUEST' });
const getJobPricesFailure = () => ({ type: 'OFFERS/JOB_PRICES_FAILURE' });
const getJobPricesSuccess = (data) => ({ type: 'OFFERS/JOB_PRICES_SUCCESS', payload: data });
const getJobPrices = () => ({ type: 'OFFERS/JOB_PRICES' });

const resetOffers = () => ({ type: 'OFFERS/RESET' });

const promoReset = () => ({ type: 'OFFERS/PROMO_RESET' });

const getUpgradeTypePriceRequest = () => ({ type: 'OFFERS/GET_UPGRADE_TYPE_PRICE_REQUEST' });
const getUpgradeTypePriceFailure = () => ({ type: 'OFFERS/GET_UPGRADE_TYPE_PRICE_FAILURE' });
const getUpgradeTypePriceSuccess = (data) => ({ type: 'OFFERS/GET_UPGRADE_TYPE_PRICE_SUCCESS', payload: { ...data } });
const getUpgradeTypePrice = (values) => ({ type: 'OFFERS/GET_UPGRADE_TYPE_PRICE', payload: { values } });

export default {
  getPrices, getPricesRequest, getPricesFailure, getPricesSuccess,
  resetOffers, promoReset, getJobPrices, getJobPricesRequest, getJobPricesFailure, getJobPricesSuccess,
  getUpgradeTypePriceRequest, getUpgradeTypePriceFailure, getUpgradeTypePriceSuccess, getUpgradeTypePrice
};