const initialState = {
  isFetching: false,
  isFetched: false,
  info: {},
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'RESUME/AVAILABLE_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'RESUME/AVAILABLE_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'RESUME/AVAILABLE_SUCCESS':
      return { ...state, isFetching: false, isFetched: true, info: { ...payload.resume } };

    case 'RESUME/AVAILABLE_DELETE_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'RESUME/AVAILABLE_DELETE_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'RESUME/AVAILABLE_DELETE_SUCCESS':
      return { ...state, isFetching: false, isFetched: true, info: {} };

    case 'RESUME/AVAILABLE_HIDE_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'RESUME/AVAILABLE_HIDE_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'RESUME/AVAILABLE_HIDE_SUCCESS':
      return { ...state, isFetching: false, isFetched: true, info: { ...state.info, hidden: payload.hidden } };

    case 'RESUME/SAVE_RESUME_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'RESUME/SAVE_RESUME_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'RESUME/SAVE_RESUME_SUCCESS':
      return { ...state, isFetching: false, isFetched: true };

    case 'RESUME/RESET':
      return initialState;
    default:
      return state;
  };
};

export default reducer;