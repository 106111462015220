const initialState = {
  isFetching: false,
  isFetched: false,
  info: null,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // case 'FORUM/SAVE_ITEM_REQUEST':
    //   return { ...state, isFetching: true, isFetched: false };
    // case 'FORUM/SAVE_ITEM_FAILURE':
    //   return { ...state, isFetching: false, isFetched: false };
    // case 'FORUM/SAVE_ITEM_SUCCESS':
    //   return { ...state, isFetching: false, isFetched: true };

    case 'EVENTS/DETAIL_ITEM_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'EVENTS/DETAIL_ITEM_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'EVENTS/DETAIL_ITEM_SUCCESS':
      return { ...state, isFetching: false, isFetched: true, info: { ...payload.event } };

    case 'EVENTS/RESET', 'EVENTS/RESET_ITEM':
      return initialState;
    default:
      return state;
  };
};

export default reducer;