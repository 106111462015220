import produce from "immer";
import persistStorage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import actions from "./actions";
import sagas from "./sagas";

const initialState = {
  isAuth: false,
  token: null,
  expires_in: null,
  isFetching: false,
  isFetched: false,
  role: null,
  id: null,
  first_name: "",
  last_name: "",
  company_name: "",
  contact_name: "",
  pagesData: null,
  facilityData: null,
  slugData: null,
};

const authReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case "AUTH/INIT_USER":
        draft.isAuth = true;
        draft.token = payload.token;
        break;

      case "AUTH/SIGN_IN_REQUEST":
        draft.isFetching = true;
        draft.isFetched = false;
        break;
      case "AUTH/SIGN_IN_FAILURE":
        draft.isFetching = false;
        draft.isFetched = false;
        break;
      case "AUTH/SIGN_IN_SUCCESS":
        draft.isFetching = false;
        draft.isFetched = true;
        draft.isAuth = true;
        draft.token = payload.token;
        draft.expires_in = payload.expires_in;
        draft.role = payload.user.role;
        draft.id = payload.user.id;
        draft.first_name = payload.user.first_name;
        draft.last_name = payload.user.last_name;
        draft.company_name = payload.user.company_name;
        draft.contact_name = payload.user.contact_name;
        break;

      case "AUTH/SIGN_UP_REQUEST":
        draft.isFetching = true;
        draft.isFetched = false;
        break;

      case "AUTH/SIGN_UP_PAGES_REQUEST":
        draft.isFetching = true;
        draft.isFetched = false;
        break;

      case "AUTH/SIGN_UP_PAGES_SLUG_REQUEST":
        draft.isFetching = true;
        draft.isFetched = false;
        break;

      case "AUTH/SIGN_UP_FAILURE":
        draft.isFetching = false;
        draft.isFetched = false;
        break;

      case "AUTH/SIGN_UP_PAGES_FAILURE":
        draft.isFetching = false;
        draft.isFetched = false;
        break;

      case "AUTH/SIGN_UP_PAGES_SLUG_FAILURE":
        draft.isFetching = false;
        draft.isFetched = false;
        break;

      case "AUTH/SIGN_UP_SUCCESS":
        draft.isFetching = false;
        draft.isFetched = true;
        break;

      case "AUTH/SIGN_UP_PAGES_SUCCESS":
        draft.isFetching = false;
        draft.isFetched = true;
        draft.pagesData = payload;
        break;

      case "AUTH/SIGN_UP_PAGES_SLUG_SUCCESS":
        draft.isFetching = false;
        draft.isFetched = true;
        draft.slugData = payload;
        break;

      case "AUTH/LOGOUT_REQUEST":
        draft.isFetching = true;
        draft.isFetched = false;
        break;
      case "AUTH/LOGOUT_FAILURE":
        draft.isFetching = false;
        draft.isFetched = false;
        break;
      case "AUTH/LOGOUT_SUCCESS":
        return { ...initialState, isFetching: false, isFetched: true };

      case "AUTH/RESET":
        return initialState;
    }
  });

const persistedReducer = persistReducer(
  {
    key: "auth",
    storage: persistStorage,
    whitelist: [
      "token",
      "isAuth",
      "id",
      "role",
      "first_name",
      "last_name",
      "company_name",
      "contact_name",
    ],
  },
  authReducer
);

export { persistedReducer as reducer, sagas };
export default {
  ...actions,
};
