const initialState = {
  isFetching: false,
  isFetched: false,
  items: 10,
  text: '',
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SEARCH/COMMON_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'SEARCH/COMMON_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'SEARCH/COMMON_SUCCESS':
      return {
        ...state, isFetching: false, isFetched: true,
        [payload.slug]: {
          list: [...payload.results],
          total: payload.total,
          page: payload.page,
        },
        text: payload.text
      };

    case 'SEARCH/RESET':
      return initialState;
    default:
      return state;
  };
};

export default reducer;