import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import ROUTES from 'config/routes';
import CaregiverRoute from './CaregiverRoute';
import CompanyRoute from './CompanyRoute';
import GuestRoute from './GuestRoute';


const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuth } = rest;
  return (
    <Route
      {...rest}
      render={props => (
        isAuth
          ? <Component {...props} />
          : <Redirect to={ROUTES.signIn} />
      )} />
  )
}

export {
  CaregiverRoute,
  CompanyRoute,
  GuestRoute,
};

export default connect(
  state => {
    return {
      isAuth: state.auth.isAuth,
    }
  }
)(PrivateRoute);