import actions from './actions';
import sagas from './sagas';

const initialState = {
  isSending: false,
  isSended: false,
  isFetching: false,
  isFetched: false,
  interests: null,
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "CONTACT/SEND_MESSAGE_REQUEST":
      return { isSending: true, isSended: false };
    case "CONTACT/SEND_MESSAGE_FAILURE":
      return { isSending: false, isSended: false };
    case "CONTACT/SEND_MESSAGE_SUCCESS":
      return { isSending: false, isSended: true };

    case "CONTACT/GET_INTERESTS_REQUEST":
      return { isFetching: true, isFetched: false };
    case "CONTACT/GET_INTERESTS_FAILURE":
      return { isFetching: false, isFetched: false };
    case "CONTACT/GET_INTERESTS_SUCCESS":
      return { isFetching: true, isFetched: true, interests: [...payload.interests] };

    case "CONTACT/RESET":
      return initialState;

    default:
      return state;
  }
};

export {
  reducer,
  sagas,
};

export default {
  ...actions
};
