import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";

import LightBoxContainer from './LightBoxContainer';


const LightBox = props => {
  const { onCloseRequest, onMovePrevRequest, onMoveNextRequest, mainSrc, prevSrc, nextSrc } = props;
  return (
    <Lightbox
      mainSrc={mainSrc}
      nextSrc={nextSrc}
      prevSrc={prevSrc}
      onCloseRequest={onCloseRequest}
      onMovePrevRequest={onMovePrevRequest}
      onMoveNextRequest={onMoveNextRequest}
    />
  )
}

export {
  LightBox as default,
  LightBoxContainer
};