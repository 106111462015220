import { takeLatest, select, call, put, all } from 'redux-saga/effects';
import { success as noticeSuccess, error } from 'react-notification-system-redux';
import store from 'store';

import Api from 'services/api/ApiService';
import ROUTES from 'config/routes';
import logger from 'services/logger';
import history from 'services/history';
import resumesActions from './actions';

function handleErrors(res) {
  if (res && res.errors) {
    throw new Error(res);
  }
  if (res && res.error) {
    throw new Error(res.error.message);
  }
}

function* getResumeDetailWatcher() {
  yield takeLatest('RESUME/DETAIL', getResumeDetailEffect);
}
function* getResumeDetailEffect(action) {
  try {
    yield put(resumesActions.getResumeDetailRequest());
    const { id } = action.payload;
    const response = yield call(() => {
      return Api.resumeDetail(id)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.getResumeDetailSuccess(response));
  } catch (error) {
    logger(error);
    yield put(resumesActions.getResumeDetailFailure());
  }
}

function* getResumeAvailableWatcher() {
  yield takeLatest('RESUME/AVAILABLE', getResumeAvailableEffect);
}
function* getResumeAvailableEffect(action) {
  try {
    yield put(resumesActions.getResumeAvailableRequest());
    const response = yield call(() => {
      return Api.resumeAvailable()
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.getResumeAvailableSuccess(response));
  } catch (error) {
    logger(error);
    yield put(resumesActions.getResumeAvailableFailure());
  }
}

function* deleteResumeAvailableWatcher() {
  yield takeLatest('RESUME/AVAILABLE_DELETE', deleteResumeAvailableEffect);
}
function* deleteResumeAvailableEffect(action) {
  try {
    yield put(resumesActions.deleteResumeAvailableRequest());
    const response = yield call(() => {
      return Api.resumeDelete()
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.deleteResumeAvailableSuccess());
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
  } catch (error) {
    logger(error);
    yield put(resumesActions.deleteResumeAvailableFailure());
  }
}

function* hideResumeAvailableWatcher() {
  yield takeLatest('RESUME/AVAILABLE_HIDE', hideResumeAvailableEffect);
}
function* hideResumeAvailableEffect(action) {
  try {
    yield put(resumesActions.hideResumeAvailableRequest());
    const { hidden } = action.payload;
    const response = yield call(() => {
      return Api.resumeHide(hidden)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.hideResumeAvailableSuccess(hidden));
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
  } catch (error) {
    logger(error);
    yield put(resumesActions.hideResumeAvailableFailure());
  }
}

function* saveResumeWatcher() {
  yield takeLatest('RESUME/SAVE_RESUME', saveResumeEffect);
}
function* saveResumeEffect(action) {
  try {
    const { id, data, photo, file } = action.payload;
    yield put(resumesActions.saveResumeRequest());
    const response = yield call(() => {
      return Api.saveResume({ ...action.payload })
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.saveResumeSuccess());
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
    yield call(history.push, ROUTES.profileRequirements);
  } catch (error) {
    logger(error);
    yield put(resumesActions.saveResumeFailure());
  }
}

function* toggleFavoriteResumeWatcher() {
  yield takeLatest('RESUME/TOGGLE_FAVORITE', toggleFavoriteResumeEffect);
}
function* toggleFavoriteResumeEffect(action) {
  try {
    const { id, liked, job_id } = action.payload;
    yield put(resumesActions.toggleFavoriteResumeRequest());
    const response = yield call(() => {
      return Api.toggleFavoriteResume({ id, liked, job_id })
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.toggleFavoriteResumeSuccess(id, liked));
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
  } catch (error) {
    logger(error);
    yield put(resumesActions.toggleFavoriteResumeFailure());
  }
}

function* renewResumeWatcher() {
  yield takeLatest('RESUME/RENEW', renewResumeEffect);
}
function* renewResumeEffect(action) {
  try {
    yield put(resumesActions.renewResumeRequest());
    const response = yield call(() => {
      return Api.resumeRenew()
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.renewResumeSuccess());
    const title = "Thank you!";
    const text = "Your job application has been successfully published to the Summary of Caregiver Profiles."
    yield call(() => history.push({
      pathname: ROUTES.thankYou,
      state: {title, text},
    }));
    yield call(() => {
      store.dispatch(noticeSuccess({
        message: response.message,
      }));
    });
  } catch (error) {
    logger(error);
    yield put(resumesActions.renewResumeFailure());
  }
}

function* getResumesCatalogWatcher() {
  yield takeLatest('RESUME/CATALOG_LIST', getResumesCatalogEffect);
}
function* getResumesCatalogEffect(action) {
  try {
    yield put(resumesActions.getResumesCatalogRequest());
    const {page, items, job_id, values} = action.payload;
    const response = yield call(() => {
      return Api.resumeCatalog(page, items, job_id, values)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.getResumesCatalogSuccess({...response, page: page}));
  } catch (error) {
    logger(error);
    yield put(resumesActions.getResumesCatalogFailure());
  }
}

function* getFavoriteResumesIdsWatcher() {
  yield takeLatest('RESUME/FAVORITE_IDS', getFavoriteResumesIdsEffect);
}
function* getFavoriteResumesIdsEffect(action) {
  try {
    yield put(resumesActions.getFavoriteResumesIdsRequest());
    const response = yield call(() => {
      return Api.getFavoriteResumesIds()
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.getFavoriteResumesIdsSuccess({...response}));
  } catch (error) {
    logger(error);
    yield put(resumesActions.getFavoriteResumesIdsFailure());
  }
}

function* getFavoriteResumesIdsByJobWatcher() {
  yield takeLatest('RESUME/FAVORITE_IDS_BY_JOB', getFavoriteResumesIdsByJobEffect);
}
function* getFavoriteResumesIdsByJobEffect(action) {
  try {
    yield put(resumesActions.getFavoriteResumesIdsByJobRequest());
    const response = yield call(() => {
      const {job_id} = action.payload;
      return Api.getFavoriteResumesIdsByJob(job_id)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.getFavoriteResumesIdsByJobSuccess({...response}));
  } catch (error) {
    logger(error);
    yield put(resumesActions.getFavoriteResumesIdsByJobFailure());
  }
}

function* getFavoriteResumesWatcher() {
  yield takeLatest('RESUME/FAVORITE_LIST', getFavoriteResumesEffect);
}
function* getFavoriteResumesEffect(action) {
  try {
    yield put(resumesActions.getFavoriteResumesRequest());
    const {page, items} = action.payload;
    const response = yield call(() => {
      return Api.getFavoriteResumes(page, items)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.getFavoriteResumesSuccess({...response}));
  } catch (error) {
    logger(error);
    yield put(resumesActions.getFavoriteResumesFailure());
  }
}

function* getFavoriteResumesByJobWatcher() {
  yield takeLatest('RESUME/FAVORITE_LIST_BY_JOB', getFavoriteResumesByJobEffect);
}
function* getFavoriteResumesByJobEffect(action) {
  try {
    yield put(resumesActions.getFavoriteResumesByJobRequest());
    const {page, items, job_id} = action.payload;
    const response = yield call(() => {
      return Api.getFavoriteResumesByJob(page, items, job_id)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.getFavoriteResumesJobSuccess({...response}));
  } catch (error) {
    logger(error);
    yield put(resumesActions.getFavoriteResumesJobFailure());
  }
}

function* getFavoriteByJobWatcher() {
  yield takeLatest('RESUME/FAVORITE_BY_JOB', getFavoriteByJobEffect);
}
function* getFavoriteByJobEffect(action) {
  try {
    const {job_id, resume_id} = action.payload;
    const response = yield call(() => {
      return Api.getFavoriteResumesStatusByJob({job_id, resume_id})
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(resumesActions.getFavoriteByJobSuccess({...response}));
  } catch (error) {
    logger(error);
  }
}


export default function* resumesWatcher() {
  yield all([
    getResumeDetailWatcher(),
    getResumeAvailableWatcher(),
    hideResumeAvailableWatcher(),
    deleteResumeAvailableWatcher(),
    saveResumeWatcher(),
    toggleFavoriteResumeWatcher(),
    renewResumeWatcher(),
    getResumesCatalogWatcher(),
    getFavoriteResumesIdsWatcher(),
    getFavoriteResumesWatcher(),
    getFavoriteResumesByJobWatcher(),
    getFavoriteResumesIdsByJobWatcher(),
    getFavoriteByJobWatcher()
  ]);
};