import { takeLatest, takeEvery, call, put, all } from 'redux-saga/effects';

import Api from 'services/api/ApiService';
import logger from 'services/logger';
import offersActions from './actions';

function handleErrors(res) {
  if (res && res.errors) {
    throw new Error(res);
  }
  if (res && res.error) {
    throw new Error(res.error.message);
  }
}

function* getPricesWatcher() {
  yield takeEvery('OFFERS/PRICES', getPricesEffect);
  yield takeEvery('OFFERS/JOB_PRICES', getJobPlanTariffs);
}
function* getPricesEffect(action) {
  try {
    const { values } = action.payload;
    yield put(offersActions.getPricesRequest());
    const response = yield call(() => {
      return Api.getOfferPrices(values)
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    yield put(offersActions.getPricesSuccess({ ...response, values }));
  } catch (error) {
    logger(error);
    yield put(offersActions.getPricesFailure());
  }
}

function* getUpgradeTypePriceWatcher() {
  yield takeLatest("OFFERS/GET_UPGRADE_TYPE_PRICE", getUpgradeTypePriceEffect);
}

function* getUpgradeTypePriceEffect(action) {
  try {
    yield put(offersActions.getUpgradeTypePriceRequest());
    const { values } = action.payload;
    const response = yield call(() => {
      return Api.getUpgradePrice(values).then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(offersActions.getUpgradeTypePriceSuccess({ ...response, values }));
  } catch (error) {
    logger(error);
    yield put(offersActions.getUpgradeTypePriceFailure());
  }
}

function* getJobPlanTariffs() {
  try {
    yield put(offersActions.getJobPricesRequest());
    const response = yield call(() => {
      return Api.getJobPlans()
        .then(res => {
          handleErrors(res);
          return res;
        })
    });
    if (response.success) yield put(offersActions.getJobPricesSuccess(response.plan_job.description.days));
  } catch (error) {
    logger(error);
    yield put(offersActions.getJobPricesFailure());
  }
}


export default function* offersWatcher() {
  yield all([
    getPricesWatcher(),
    getUpgradeTypePriceWatcher()
  ]);
};