import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LightboxModule from 'modules/lightbox';
import LightBox from 'components/LightBox';


class LightBoxContainer extends Component {
  handleClose = () => {
    const { hide, onClose } = this.props;

    if (typeof onClose !== 'function') hide();
    else onClose();
  }

  render() {
    const { isOpen, photo } = this.props;

    return (
      <>
        {
          isOpen
            ? <LightBox
              mainSrc={photo}
              // nextSrc={nextImage}
              // prevSrc={prevImage}
              // onMovePrevRequest={onMovePrevRequest}
              // onMoveNextRequest={onMoveNextRequest}
              onCloseRequest={this.handleClose} />
            : <></>
        }
      </>
    )
  }
}

LightBoxContainer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  photo: PropTypes.string,
}


export default connect(
  state => {
    const { isOpen, onClose, onNext, onPrev, photo } = state.lightbox;

    return {
      isOpen,
      onClose,
      onNext,
      onPrev,
      photo,
    }
  },
  {
    hide: LightboxModule.hide
  }
)(LightBoxContainer);