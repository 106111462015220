const ROUTES = {
  home: '/',
  aboutUs: '/about-us',
  aboutForum: '/about-forum',
  aboutMarketplace: '/about-marketplace',
  advertisementInfo: '/advertisement',
  advertisingSponsorship: '/advertising-sponsorship',
  article: '/articles',
  articles: '/articles',
  careerCenter: '/career-center',
  careerCenterInfo: '/career-center-info',
  catalogJobs: '/job-posts',
  catalogResume: '/job-applications',
  contactUs: '/contact-us',
  sitemap: '/sitemap',
  createAdvertisement: '/create-advertisement',
  createForumApplication: '/create-forums-post',
  createJob: '/create-job-post',
  createResume: '/create-job-application',
  editAdvertisement: '/edit-advertisement',
  editForum: '/edit-forum-content',
  editJob: '/edit-job-post',
  editResume: '/edit-job-application',
  educationTrainingCertification: '/education-training-certification',
  events: '/events',
  forum: '/forum',
  forumDetails: '/forums-details',
  jobChoosing: '/job-choosing',
  jobDetail: '/job-post',
  marketplace: '/marketplace',
  membership: '/membership',
  mdlive: '/mdlive',
  newPass: '/new-password',
  page404: '/404',
  paymentForum: '/payment-forum',
  paymentJob: '/payment-job',
  paymentMarketplace: '/payment-marketplace',
  paymentUpgrade: '/payment-upgrade',
  privacy: '/privacy',
  profile: '/profile',
  profileInfo: '/profile/info',
  profileRequirements: '/profile/requirements',
  profileJobListing: '/profile/job-listing',
  profileFavorites: '/profile/favorites',
  profileForum: '/profile/my-forum-content',
  profileAdverts: '/profile/advertisements',
  profilePassword: '/profile/password',
  restorePass: '/restore-password',
  resumeDetail: '/job-application',
  searchResults: '/search-results',
  signIn: '/sign-in',
  signUp: '/sign-up',
  signUpCaregivers: '/sign-up/caregivers',
  signUpCompanies: '/sign-up/companies',
  signUpOther: '/sign-up/other',
  signUpConfirmed: '/sign-up-confirmed',
  terms: '/terms-conditions',
  thankYou: '/thank-you',
  underconstruction: '/under-construction',
};

export default ROUTES;
