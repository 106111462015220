import React from "react";
import PropTypes from "prop-types";
import ICON_NAMES from "./iconNames";

const IconTemplate = (props) => {
  const { children, width, height, className, strictSize, title, alt } = props;

  return (
    <svg
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${width} ${height}`}
      className={`icon ${className}`}
      style={{
        width: strictSize ? `${width}px` : "1em",
        height: strictSize ? `${height}px` : `${height / width}em`,
      }}
      aria-label={alt}
    >
      <title>{title}</title>
      {children}
    </svg>
  );
};

IconTemplate.defaultProps = {
  className: "",
};

const Icon = (props) => {
  const { name, active } = props;

  switch (name) {
    case ICON_NAMES.add:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M14,11v3h-3v2h3v3h2v-3h3v-2h-3v-3H14z M15,7c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S19.4,7,15,7L15,7z M9,15
	c0-3.3,2.7-6,6-6s6,2.7,6,6s-2.7,6-6,6S9,18.3,9,15z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.agreement:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M15,4.7c-0.3,0-0.6,0.3-0.6,0.6v1.8c0,0.3,0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6V5.3C15.6,5,15.3,4.7,15,4.7z M11.9,8.4l-1.2-1.2C10.5,7,10.1,7,9.9,7.2C9.7,7.4,9.7,7.8,9.9,8l1.2,1.2c0.2,0.2,0.6,0.2,0.8,0C12.1,9,12.1,8.6,11.9,8.4z M20.1,7.2c-0.2-0.2-0.6-0.2-0.8,0l-1.2,1.2c-0.2,0.2-0.2,0.6,0,0.8s0.6,0.2,0.8,0L20.1,8C20.3,7.8,20.3,7.5,20.1,7.2z M24.4,12.9
	h-1.8v8.2h1.8c0.3,0,0.6-0.3,0.6-0.6v-7C25,13.2,24.7,12.9,24.4,12.9z M5.6,12.9c-0.3,0-0.6,0.3-0.6,0.6v7c0,0.3,0.3,0.6,0.6,0.6
	h1.8v-8.2C7.4,12.9,5.6,12.9,5.6,12.9z M14.6,13.4c-0.4,0-0.8,0.3-1,0.7l-1.8,3.6c0.3,0.1,0.6,0.2,0.9,0.2c0.9,0,1.7-0.5,2.1-1.3
	l0.4-0.8h1.1c0.3,0,0.6,0.1,0.8,0.3l4.3,4.3v-7H14.6L14.6,13.4z M16.3,17h-0.4l0,0c-0.6,1.1-1.6,2-2.9,2.1c-0.9,0.1-1.5-0.2-2.3-0.5
	c-0.3-0.1-0.4-0.5-0.3-0.8l2.1-4.1c0.4-0.8,1.2-1.3,2.1-1.3h2.5c0.2-0.4,0.3-0.8,0.3-1.2c0-0.3-0.3-0.6-0.6-0.6h-4.5
	c-0.7,0-1.3,0.4-1.6,1l-0.5,1c-0.1,0.2-0.3,0.3-0.5,0.3h-1v8.2h2.7l1.7,1.7c0.5,0.5,1.2,0.6,1.8,0.4l5-1.6c0.3-0.1,0.6-0.2,0.8-0.5
	L16.3,17z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.arrowDropdown:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path d="M19,12.5h-8l4,5L19,12.5z" />
        </IconTemplate>
      );
    case ICON_NAMES.arrowBack:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path d="M5.5,15l4-4v3h15v2h-15v3L5.5,15z" />
        </IconTemplate>
      );
    case ICON_NAMES.check:
      return (
        <IconTemplate {...props} width={30} height={30}>
          {active ? (
            <path
              d="M21.2,7H8.8C7.8,7,7,7.8,7,8.8v12.4c0,1,0.8,1.8,1.8,1.8h12.4c1,0,1.8-0.8,1.8-1.8V8.8C23,7.8,22.2,7,21.2,7
                L21.2,7z M13,20.4l-5-5L9.4,14l3.6,3.6l7.6-7.6l1.4,1.4L13,20.4L13,20.4z"
            />
          ) : (
            <path
              d="M21,9v12H9V9H21L21,9z M21.2,7H8.8C7.8,7,7,7.8,7,8.8v12.4c0,1,0.8,1.8,1.8,1.8h12.4c1,0,1.8-0.8,1.8-1.8V8.8
                C23,7.8,22.2,7,21.2,7L21.2,7z"
            />
          )}
        </IconTemplate>
      );
    case ICON_NAMES.close:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M19.2,17.8l-1.4,1.4L15,16.4l-2.8,2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8,2.8l2.8-2.8l1.4,1.4L16.4,15
	L19.2,17.8z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.closeCircle:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path d="M15,0C6.7,0,0,6.7,0,15s6.7,15,15,15s15-6.7,15-15S23.3,0,15,0L15,0z" />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="19,17.9 17.9,19 15,16.1 12.1,19 11,17.9 13.9,15 11,12.1 12.1,11 15,13.9 17.9,11 19,12.1
	16.1,15 "
          />
        </IconTemplate>
      );
    case ICON_NAMES.delete:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M15,7c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S19.4,7,15,7L15,7z M19,17.9L17.9,19L15,16.1L12.1,19L11,17.9
	l2.9-2.9L11,12.1l1.1-1.1l2.9,2.9l2.9-2.9l1.1,1.1L16.1,15L19,17.9L19,17.9z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.facebook:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M22.4,6H7.6C6.8,6,6,6.8,6,7.6v14.7C6,23.2,6.8,24,7.6,24H15v-7.5h-2.2v-2.2H15V12c0-1.9,0.8-3,3-3h2.2v2.2h-1
	c-0.7,0-1.3,0.6-1.3,1.3v1.7h3l-0.4,2.2H18V24h4.4c0.9,0,1.6-0.8,1.6-1.6V7.6C24,6.8,23.2,6,22.4,6z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.favorite:
      return (
        <IconTemplate {...props} width={30} height={30}>
          {active ? (
            <path
              d="M15,9.5c-0.9-1-2.2-2-3.6-2C8.9,7.5,7,9.5,7,12c0,3.1,2.7,5.6,6.8,9.4l1.2,1.1l1.2-1.1c4.1-3.8,6.8-6.3,6.8-9.4
            c0-2.5-1.9-4.5-4.4-4.5C17.2,7.5,15.9,8.5,15,9.5z"
            />
          ) : (
            <path
              d="M15,9.5c-0.9-1-2.2-2-3.6-2C8.9,7.5,7,9.5,7,12c0,3.1,2.7,5.6,6.8,9.4l1.2,1.1l1.2-1.1c4.1-3.8,6.8-6.3,6.8-9.4
            c0-2.5-1.9-4.5-4.4-4.5C17.2,7.5,15.9,8.5,15,9.5z M15,19.5L15,19.5c-3.6-3.2-6-5.3-6-7.4c0-1.5,1.1-2.6,2.6-2.6
            c1.2,0,3,1.6,3.4,2.6c0.4-1,2.2-2.6,3.4-2.6c1.5,0,2.6,1.1,2.6,2.6C21,14.2,18.6,16.3,15,19.5L15,19.5z"
            />
          )}
        </IconTemplate>
      );
    case ICON_NAMES.file:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M6,15c0-2.8,2.2-5,4.9-5h9.4c2,0,3.6,1.6,3.6,3.6s-1.6,3.6-3.6,3.6h-7.6c-1.2,0-2.2-1-2.2-2.3s1-2.3,2.2-2.3
	h6.8v1.8h-6.8c-0.5,0-0.5,0.9,0,0.9h7.7c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8h-9.4c-1.7,0-3.1,1.4-3.1,3.2s1.4,3.2,3.1,3.2h8.6V20
	h-8.6C8.2,20,6,17.8,6,15L6,15z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.filter:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path d="M13,21h4v-2h-4V21L13,21z M6,9v2h18V9H6L6,9z M9,16h12v-2H9V16L9,16z" />
        </IconTemplate>
      );
    case ICON_NAMES.hospital:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M22,6H8C6.9,6,6,6.9,6,8l0,14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V8C24,6.9,23.1,6,22,6z M21,17h-4v4h-4v-4H9
	v-4h4V9h4v4h4V17z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.instagram:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <linearGradient
            id={`gradient${active}`}
            gradientUnits="userSpaceOnUse"
            x1="1.7395"
            y1="7.3541"
            x2="2.0548"
            y2="8.5737"
            gradientTransform="matrix(16 0 0 16 -15 -111)"
          >
            <stop offset="0" stopColor="#4E60D3" />
            <stop offset="0.1428" stopColor="#913BAF" />
            <stop offset="0.7615" stopColor="#D52D88" />
            <stop offset="1" stopColor="#F26D4F" />
          </linearGradient>
          <path
            fill={`url(#gradient${active})`}
            d="M15.1,7.1c-2.2,0-2.4,0-3.3,0c-0.9,0-1.4,0.2-1.9,0.4C9.4,7.7,8.9,8,8.5,8.4C8,8.9,7.7,9.3,7.5,9.9
	c-0.2,0.5-0.3,1-0.4,1.9c0,0.9,0,1.1,0,3.3s0,2.4,0,3.3s0.2,1.4,0.4,1.9c0.2,0.5,0.5,1,0.9,1.4c0.4,0.4,0.9,0.7,1.4,0.9
	c0.5,0.2,1.1,0.3,1.9,0.4c0.9,0,1.1,0,3.3,0s2.4,0,3.3,0c0.9,0,1.4-0.2,1.9-0.4c0.5-0.2,1-0.5,1.4-0.9s0.7-0.9,0.9-1.4
	c0.2-0.5,0.3-1.1,0.4-1.9c0-0.9,0-1.1,0-3.3s0-2.4,0-3.3c0-0.9-0.2-1.4-0.4-1.9c-0.2-0.5-0.5-1-0.9-1.4c-0.4-0.4-0.9-0.7-1.4-0.9
	c-0.5-0.2-1.1-0.3-1.9-0.4C17.5,7.1,17.3,7.1,15.1,7.1L15.1,7.1z M14.4,8.5c0.2,0,0.5,0,0.7,0c2.1,0,2.4,0,3.2,0
	c0.8,0,1.2,0.2,1.5,0.3c0.4,0.1,0.6,0.3,0.9,0.6c0.3,0.3,0.5,0.5,0.6,0.9c0.1,0.3,0.2,0.7,0.3,1.5c0,0.8,0,1.1,0,3.2s0,2.4,0,3.2
	s-0.2,1.2-0.3,1.5c-0.1,0.4-0.3,0.6-0.6,0.9c-0.3,0.3-0.5,0.5-0.9,0.6c-0.3,0.1-0.7,0.2-1.5,0.3c-0.8,0-1.1,0-3.2,0s-2.4,0-3.2,0
	c-0.8,0-1.2-0.2-1.5-0.3c-0.4-0.1-0.6-0.3-0.9-0.6c-0.3-0.3-0.5-0.5-0.6-0.9c-0.1-0.3-0.2-0.7-0.3-1.5c0-0.8,0-1.1,0-3.2
	s0-2.4,0-3.2c0-0.8,0.2-1.2,0.3-1.5C9,9.9,9.2,9.7,9.5,9.4s0.5-0.5,0.9-0.6c0.3,0,0.7-0.2,1.5-0.2C12.6,8.6,12.9,8.5,14.4,8.5
	L14.4,8.5z M19.4,9.9c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1C20.3,10.3,19.9,9.9,19.4,9.9L19.4,9.9z M15.1,11
	c-2.3,0-4.1,1.8-4.1,4.1s1.8,4.1,4.1,4.1s4.1-1.8,4.1-4.1S17.4,11,15.1,11L15.1,11z M15.1,12.4c1.5,0,2.7,1.2,2.7,2.7
	s-1.2,2.7-2.7,2.7s-2.7-1.2-2.7-2.7S13.6,12.4,15.1,12.4L15.1,12.4z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.language:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M15.9,18.1l-2.5-2.5l0,0c1.7-1.9,3-4.2,3.7-6.5H20V7h-7V5h-2v2H4v2h11.2c-0.7,1.9-1.7,3.8-3.2,5.4
	c-0.9-1-1.7-2.2-2.3-3.4h-2c0.7,1.6,1.7,3.2,3,4.6l-5.1,5L7,22l5-5l3.1,3.1C15.1,20.1,15.9,18.1,15.9,18.1z M21.5,13h-2L15,25h2
	l1.1-3h4.8l1.1,3h2L21.5,13z M18.9,20l1.6-4.3l1.6,4.3C22.1,20,18.9,20,18.9,20z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.linkedin:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M18.5,12.3c2,0,3.5,1.4,3.5,4.3l0,0l0,5.4h-3v-5.1c0-1.3-0.4-2.1-1.5-2.1c-0.8,0-1.3,0.6-1.5,1.1
	c-0.1,0.2-0.1,0.5-0.1,0.8l0,0V22h-3c0,0,0-8.6,0-9.4l0,0h3v1.3C16.2,13.2,17,12.3,18.5,12.3z M11.2,12.6V22h-3v-9.4H11.2z M9.7,8
	c1,0,1.7,0.7,1.7,1.6c0,0.9-0.7,1.6-1.7,1.6l0,0h0c-1,0-1.7-0.7-1.7-1.6C8,8.7,8.7,8,9.7,8z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.minus:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <polygon points="16,16 14,16 10,16 10,14 14,14 16,14 20,14 20,16 " />
        </IconTemplate>
      );
    case ICON_NAMES.more:
      return (
        <IconTemplate
          {...props}
          width={30}
          height={30}
          title="Click here for more functions"
        >
          <path
            d="M15,11c1.1,0,2-0.9,2-2s-0.9-2-2-2s-2,0.9-2,2S13.9,11,15,11L15,11z M15,13c-1.1,0-2,0.9-2,2s0.9,2,2,2
	s2-0.9,2-2S16.1,13,15,13L15,13z M15,19c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S16.1,19,15,19L15,19z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.password:
      return (
        <IconTemplate {...props} width={30} height={30}>
          {active ? (
            <path
              d="M15,8.3c-4.1,0-7.6,2.6-9,6.3c1.4,3.7,4.9,6.3,9,6.3s7.6-2.6,9-6.3C22.6,10.9,19.1,8.3,15,8.3L15,8.3z M15,18.7
	c-2.3,0-4.1-1.9-4.1-4.2s1.8-4.2,4.1-4.2s4.1,1.9,4.1,4.2S17.3,18.7,15,18.7L15,18.7z M15,12c-1.4,0-2.5,1.1-2.5,2.5
	c0,1.4,1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5C17.5,13.2,16.4,12,15,12L15,12z"
            />
          ) : (
            <path
              d="M15,10.4c2.3,0,4.1,1.9,4.1,4.2c0,0.5-0.1,1.1-0.3,1.5l2.4,2.5c1.2-1.1,2.2-2.4,2.8-4c-1.4-3.7-4.9-6.3-9-6.3
	c-1.1,0-2.2,0.2-3.3,0.6l1.8,1.8C14,10.5,14.5,10.4,15,10.4L15,10.4z M6.8,8.1L8.7,10l0.4,0.4c-1.4,1.1-2.4,2.5-3.1,4.2
	c1.4,3.7,4.9,6.3,9,6.3c1.3,0,2.5-0.3,3.6-0.7l0.3,0.4l2.4,2.5l1-1.1L7.9,7L6.8,8.1L6.8,8.1z M11.3,12.7l1.3,1.3
	c0,0.2-0.1,0.4-0.1,0.5c0,1.4,1.1,2.5,2.5,2.5c0.2,0,0.4,0,0.5-0.1l1.3,1.3c-0.5,0.3-1.2,0.4-1.8,0.4c-2.3,0-4.1-1.9-4.1-4.2
	C10.9,13.9,11.1,13.3,11.3,12.7L11.3,12.7z M14.9,12.1l2.6,2.7l0-0.1c0-1.4-1.1-2.5-2.5-2.5L14.9,12.1L14.9,12.1z"
            />
          )}
        </IconTemplate>
      );
    case ICON_NAMES.pets:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M7.5,10c1.4,0,2.5,1.1,2.5,2.5S8.9,15,7.5,15S5,13.9,5,12.5S6.1,10,7.5,10z M12,6c1.4,0,2.5,1.1,2.5,2.5
	S13.4,11,12,11S9.5,9.9,9.5,8.5S10.6,6,12,6z M18,6c1.4,0,2.5,1.1,2.5,2.5S19.4,11,18,11c-1.4,0-2.5-1.1-2.5-2.5S16.6,6,18,6z
	 M22.5,10c1.4,0,2.5,1.1,2.5,2.5S23.9,15,22.5,15S20,13.9,20,12.5S21.1,10,22.5,10z M20.3,17.9c-0.9-1-1.6-1.9-2.5-2.9
	c-0.5-0.5-1-1.1-1.8-1.3c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.5,0-0.8,0s-0.5,0-0.8,0.1c-0.1,0-0.2,0.1-0.3,0.1
	c-0.7,0.2-1.3,0.8-1.8,1.3c-0.9,1-1.6,1.9-2.5,2.9c-1.3,1.3-2.9,2.8-2.6,4.8c0.3,1,1,2,2.3,2.3c0.7,0.1,3.1-0.4,5.5-0.4h0.2
	c2.5,0,4.8,0.6,5.5,0.4c1.3-0.3,2-1.3,2.3-2.3C23.3,20.6,21.7,19.2,20.3,17.9z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.play:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path d="M12,19.5l6-4.5l-6-4.5V19.5z" />
        </IconTemplate>
      );
    case ICON_NAMES.plus:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path d="M20,14v2h-4v4h-2v-4h-4v-2h4v-4h2v4H20z" />
        </IconTemplate>
      );
    case ICON_NAMES.radio:
      return (
        <IconTemplate {...props} width={30} height={30}>
          {active ? (
            <path
              d="M15,7c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S10.6,7,15,7z M15,9c-3.3,0-6,2.7-6,6
                  s2.7,6,6,6s6-2.7,6-6S18.3,9,15,9z M15,11c2.2,0,4,1.8,4,4s-1.8,4-4,4s-4-1.8-4-4S12.8,11,15,11z"
            />
          ) : (
            <path
              d="M15,7c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S19.4,7,15,7L15,7z M15,21c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6
                  S18.3,21,15,21L15,21z"
            />
          )}
        </IconTemplate>
      );
    case ICON_NAMES.reset:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M9.4,9.4L7,7v7h7l-3.2-3.2C11.9,9.7,13.3,9,15,9c3.3,0,6,2.7,6,6s-2.7,6-6,6c-2.6,0-4.8-1.7-5.6-4
	H7.3c0.9,3.5,4,6,7.7,6c4.4,0,8-3.6,8-8s-3.6-8-8-8C12.8,7,10.8,7.9,9.4,9.4L9.4,9.4z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.search:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M14.6,7c-2,0-3.9,0.7-5.4,2.2c-3,3-3,7.8,0,10.8c1.5,1.5,3.4,2.2,5.4,2.2c1.6,0,3.2-0.5,4.6-1.5
      l1.9,1.9c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.4-0.4,0.4-1.1,0-1.5l-1.9-1.9c2.2-3,2-7.3-0.7-10C18.6,7.7,16.6,7,14.6,7M14.6,9c1.5,0,2.9,0.6,4,1.7c2,2,2.2,5.1,0.5,7.4l-0.5,0.7L18,19.2c-1,0.7-2.1,1.1-3.4,1.1c-1.5,0-2.9-0.6-4-1.7
      c-2.2-2.2-2.2-5.8,0-8C11.7,9.6,13.1,9,14.6,9"
          />
        </IconTemplate>
      );
    case ICON_NAMES.selected:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path d="M13,19.9l-5-5l1.4-1.4l3.6,3.6l7.6-7.6l1.4,1.4L13,19.9z" />
        </IconTemplate>
      );
    case ICON_NAMES.statusCheck:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M24,15l-2-2.3l0.3-3l-3-0.7l-1.5-2.6L15,7.7l-2.8-1.2l-1.5,2.6l-3,0.7l0.3,3L6,15l2,2.3l-0.3,3l3,0.7l1.5,2.6
	l2.8-1.2l2.8,1.2l1.5-2.6l3-0.7l-0.3-3L24,15L24,15z M13.4,19L9.3,15l1.2-1.1l2.9,2.9l6.2-6.2l1.2,1.2L13.4,19L13.4,19z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.symb:
      return (
        <IconTemplate {...props} width={120} height={120}>
          <path
            d="M112.7,60.8c-6.6,13-17.2,23.9-27.8,33.8c-5.4,5-11,9.8-16.8,14.3c-3,2.4-6.1,4.7-9.3,7c-2.4,1.7-5,4.7-8.1,4
    c-6.1,0.3-10.1-2.5-10.8-9.3c-0.3-3.3,1.3-8.6-1.2-11.2c-3-3.2-7.5-0.1-9.8,2.3c-3.4,3.5-5-0.4-2.7-2.8c3.6-9.3,6.1-11.8,10-11.4
    c3.7,0.4,6.2,3.4,7.5,6.7c1.6,3.9,2.2,9.7,1.4,13.9c0,0.1,0,0.2,0,0.4c0,0,0.1,0.1,0.1,0.1c0.6,0.3,1.2,0.7,1.9,0.9
    c1.7,0.4,3.6,0.1,5.3-0.1c0.2,0,0.3,0,0.5,0c1.1-0.6,2.3-1.9,2.9-2.3c1.6-1.1,3.1-2.3,4.7-3.4c2.8-2.1,5.5-4.2,8.2-6.3
    c5.8-4.7,11.4-9.6,16.8-14.7c8.9-8.6,17.8-17.9,23.4-29c1.4-2.8,2.8-5.8,3.4-8.9c0.1-0.6,0.2-1.2,0.3-1.8c0-0.2,0.1-1,0.2-1.5
    c0-0.3,0-0.7,0-0.7c0-0.7,0-1.4-0.1-2.1c-0.5-4.7-2.5-9.6-5.1-13.6c-5.4-8.1-14.8-12.8-24.3-13.8c-12.4-1.3-21.9,4.7-30,13.5
    c-0.8,0.9-1.9,0.9-2.7,0c-5.6-6.1-11.7-11.2-20-12.9C22.1,9.7,13,11,5.4,14.9C1.8,16.7,0.8,6.2,4,4.5C12.3,0.3,22.4-1.2,31.6,1
    C40,2.9,46.2,7.6,51.9,13.6C56.7,8.6,62,4.1,68.7,1.8c6.9-2.3,14-2.3,21-0.4c10.8,2.9,19.4,10,24.1,20.1
    C119.8,34.5,119.1,48.2,112.7,60.8z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.tm:
      return (
        <IconTemplate {...props} width={20} height={9}>
          <path d="M0,0h7.8v2H5.2v8H2.6V2H0V0z M18.3,10V4l-2.5,5h-1.7l-2.5-5v6H10V0h1.7L15,6l3.3-6H20v10H18.3z" />
        </IconTemplate>
      );
    case ICON_NAMES.twitter:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M16.7,13.9l5.7-6.1h-2l-4.6,4.9l-3.7-4.9H7l6,8l-6.1,6.6h2l5-5.4l4,5.4H23L16.7,13.9z M11.4,9.2l8.7,11.6h-1.4
	L10,9.2H11.4z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.user:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M15,9c-1.7,0-3,1.3-3,3s1.3,3,3,3s3-1.3,3-3S16.7,9,15,9L15,9z M15,15.8c-2,0-6,1-6,3V21h12v-2.2
	C21,16.8,17,15.8,15,15.8L15,15.8z"
          />
        </IconTemplate>
      );
    case ICON_NAMES.visit:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <polygon points="25,15 21,11 21,14 6,14 6,16 21,16 21,19 " />
        </IconTemplate>
      );
    case ICON_NAMES.youtube:
      return (
        <IconTemplate {...props} width={30} height={30}>
          <path
            d="M22.8,11.9c0,0-0.2-1.1-0.6-1.6c-0.6-0.6-1.3-0.6-1.6-0.7C18.4,9.5,15,9.5,15,9.5h0c0,0-3.4,0-5.6,0.2
	c-0.3,0-1,0-1.6,0.7c-0.5,0.5-0.6,1.6-0.6,1.6S7,13.1,7,14.4v1.2c0,1.3,0.2,2.5,0.2,2.5s0.2,1.1,0.6,1.6c0.6,0.6,1.4,0.6,1.8,0.7
	c1.3,0.1,5.4,0.2,5.4,0.2s3.4,0,5.6-0.2c0.3,0,1,0,1.6-0.7c0.5-0.5,0.6-1.6,0.6-1.6s0.2-1.3,0.2-2.5v-1.2
	C23,13.1,22.8,11.9,22.8,11.9L22.8,11.9z"
          />
          <path
            className="youtube-play"
            fill="#fff"
            d="M13,16.9l0-4.4l4.3,2.2L13,16.9z"
          />
        </IconTemplate>
      );

    default:
      return null;
  }
};

Icon.propTypes = {
  name: PropTypes.string,
  strictSize: PropTypes.bool,
  active: PropTypes.bool,
};

export { Icon as default, ICON_NAMES };
