const getProfileInfoRequest = () => ({ type: 'PROFILE/GET_INFO_REQUEST' });
const getProfileInfoFailure = () => ({ type: 'PROFILE/GET_INFO_FAILURE' });
const getProfileInfoSuccess = (data) => ({ type: 'PROFILE/GET_INFO_SUCCESS', payload: { ...data } });
const getProfileInfo = () => ({ type: 'PROFILE/GET_INFO' });

const editProfileRequest = () => ({ type: 'PROFILE/EDIT_INFO_REQUEST' });
const editProfileFailure = () => ({ type: 'PROFILE/EDIT_INFO_FAILURE' });
const editProfileSuccess = (data) => ({ type: 'PROFILE/EDIT_INFO_SUCCESS', payload: { ...data } });
const editProfile = (values) => ({ type: 'PROFILE/EDIT_INFO', payload: { values } });

const changePasswordRequest = () => ({ type: 'PROFILE/CHANGE_PASSWORD_REQUEST' });
const changePasswordFailure = () => ({ type: 'PROFILE/CHANGE_PASSWORD_FAILURE' });
const changePasswordSuccess = (data) => ({ type: 'PROFILE/CHANGE_PASSWORD_SUCCESS', payload: { ...data } });
const changePassword = (values) => ({ type: 'PROFILE/CHANGE_PASSWORD', payload: { values } });


export default {
  getProfileInfo, getProfileInfoRequest, getProfileInfoFailure, getProfileInfoSuccess,
  editProfile, editProfileRequest, editProfileFailure, editProfileSuccess,
  changePassword, changePasswordRequest, changePasswordFailure, changePasswordSuccess,
};