const initialState = {
  isFetching: false,
  isFetched: false,
  page: 1,
  items: 10,
  total: 0,
  category_id: null,
  list: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FORUM/CATALOG_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'FORUM/CATALOG_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'FORUM/CATALOG_SUCCESS':
      return { ...state, isFetching: false, isFetched: true, page: payload.page, category_id: payload.category_id, total: payload.total, list: [...payload.forums] };

    case 'FORUM/RESET':
      return initialState;
    default:
      return state;
  };
};

export default reducer;