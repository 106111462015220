const initialState = {
  isFetching: false,
  isFetched: false,
  list: [],
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'MARKETPLACE/FEATURED_CATALOG_REQUEST':
      return { ...state, isFetching: true, isFetched: false };
    case 'MARKETPLACE/FEATURED_CATALOG_FAILURE':
      return { ...state, isFetching: false, isFetched: false };
    case 'MARKETPLACE/FEATURED_CATALOG_SUCCESS':
      return { ...state, isFetching: false, isFetched: true, list: [...payload.marketplaces] };

    case 'MARKETPLACE/RESET':
      return initialState;
    default:
      return state;
  };
};

export default reducer;