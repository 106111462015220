import React, { Fragment, useEffect, Component, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import Modal from 'react-modal';
import { PersistGate } from "redux-persist/lib/integration/react";
import { Helmet } from "react-helmet";
import { FacebookProvider } from 'react-facebook';

import * as serviceWorker from './serviceWorker';
import store, { persistor } from 'store';
import ROUTES from 'config/routes';
import history from 'services/history';
import AppEnterModule from 'modules/appEnter';
import { FACEBOOK_APP_ID } from 'config';

import PagePreloader from 'components/PagePreloader';
import Notice from 'components/Notice';
import ModalsContainer from 'components/Modal/ModalsContainer';
import LightBoxContainer from 'components/LightBox/LightBoxContainer';
import PrivateRoute, { CaregiverRoute, CompanyRoute, GuestRoute } from 'components/PrivateRoute';
import Preloader from 'components/Preloader';

import 'styles/styles.scss';

const Home = lazy(() => import('pages/Home'));
const ContactUs = lazy(() => import('pages/ContactUs'));
const UnderConstruction = lazy(() => import('pages/UnderConstruction'));
const AboutUs = lazy(() => import('pages/AboutUs'));
const SignIn = lazy(() => import('pages/SignIn'));
const RestorePass = lazy(() => import('pages/RestorePass'));
const NewPass = lazy(() => import('pages/NewPass'));
const SignUp = lazy(() => import('pages/SignUp'));
const SignUpOther = lazy(() => import('pages/SignUp/SignUpOther'));
const SignUpCaregivers = lazy(() => import('pages/SignUp/SignUpCaregivers'));
const SignUpCompanies = lazy(() => import('pages/SignUp/SignUpCompanies'));
const SignUpOtherForm = lazy(() => import('pages/SignUp/OtherRegistration'));
const SignUpCaregiversForm = lazy(() => import('pages/SignUp/CaregiversRegistration'));
const SignUpCompaniesForm = lazy(() => import('pages/SignUp/CompaniesRegistration'));
const ThankYou = lazy(() => import('pages/ThankYou'));
const TermsConditions = lazy(() => import('pages/TermsConditions'));
const Privacy = lazy(() => import('pages/Privacy'));
const SignUpConfirmed = lazy(() => import('pages/SignUpConfirmed'));
const Profile = lazy(() => import('pages/Profile'));
const CreateResume = lazy(() => import('pages/CreateResume'));
const CreateJob = lazy(() => import('pages/CreateJob'));
const CatalogResume = lazy(() => import('pages/CatalogResume'));
const CatalogJobs = lazy(() => import('pages/CatalogJobs'));
const ResumeDetail = lazy(() => import('pages/ResumeDetail'));
const JobDetail = lazy(() => import('pages/JobDetail'));
const JobEdit = lazy(() => import('pages/JobEdit'));
const ResumeEdit = lazy(() => import('pages/ResumeEdit'));
const Membership = lazy(() => import('pages/Membership'));
const Marketplace = lazy(() => import('pages/Marketplace'));
const CreateAdvertisement = lazy(() => import('pages/CreateAdvertisement'));
const Forum = lazy(() => import('pages/Forum'));
const ForumDetails = lazy(() => import('pages/Forum/Details'));
const ForumApplication = lazy(() => import('pages/Forum/Application'));
const CareerCenter = lazy(() => import('pages/CareerCenter'));
const CareerInfo = lazy(() => import('pages/CareerCenter/Info'));
const EditAdvertisement = lazy(() => import('pages/EditAdvertisement'));
const PaymentMarketplace = lazy(() => import('pages/PaymentMarketplace'));
const PaymentUpgrade = lazy(() => import('pages/PaymentUpgrade'));
const AdvertisingSponsorship = lazy(() => import('pages/AdvertisingSponsorship'));
const AboutMarketplace = lazy(() => import('pages/AboutMarketplace'));
const AboutForum = lazy(() => import('pages/AboutForum'));
const AdvertisementDetail = lazy(() => import('pages/AdvertisementDetail'));
const Articles = lazy(() => import('pages/Articles'));
const Article = lazy(() => import('pages/Article'));
const EditForumContent = lazy(() => import('pages/EditForumContent'));
const PaymentForum = lazy(() => import('pages/PaymentForum'));
const PaymentJob = lazy(() => import('pages/PaymentJob'));
const Page404 = lazy(() => import('pages/Page404'));
const Events = lazy(() => import('pages/Events'));
const EventDetail = lazy(() => import('pages/EventDetail'));
const MdLive = lazy(() => import('pages/MDLive'));
const SearchResults = lazy(() => import('pages/SearchResults'));
const JobChoosing = lazy(() => import('pages/JobChoosing'));
const EducationTrainingCertification = lazy(() => import('pages/EducationTrainingCertification'));

Modal.setAppElement('#root');
class App extends Component {
  async componentWillMount() {
    this.props.initApp();
  }

  render() {
    const { isLoaded } = this.props;
    if (!isLoaded) return null;

    return (
      <Router history={history}>
        <Fragment>
          <Helmet>
            <title>WeCare4</title>
            <meta name="description" content="WeCare4" />
          </Helmet>

          <Suspense fallback={<Preloader />}>
            <Switch>
              <Route exact path={ROUTES.home} component={Home} />
              <Route exact path={ROUTES.contactUs} component={ContactUs} />
              <Route exact path={ROUTES.aboutUs} component={AboutUs} />
              <Route exact path={ROUTES.privacy} component={Privacy} />
              <Route exact path={ROUTES.terms} component={TermsConditions} />
              <Route exact path={ROUTES.advertisingSponsorship} component={AdvertisingSponsorship} />
              <Route exact path={ROUTES.aboutMarketplace} component={AboutMarketplace} />
              <Route exact path={ROUTES.aboutForum} component={AboutForum} />
              <GuestRoute exact path={ROUTES.signIn} component={SignIn} />
              <GuestRoute exact path={ROUTES.restorePass} component={RestorePass} />
              <Route exact path={ROUTES.newPass} component={NewPass} />
              <GuestRoute exact path={ROUTES.signUp} component={SignUp} />
              <GuestRoute exact path={ROUTES.signUpCaregivers} component={SignUpCaregivers} />
              <GuestRoute exact path={`${ROUTES.signUpCaregivers}/:slug`} component={SignUpCaregiversForm} />
              <GuestRoute exact path={ROUTES.signUpCompanies} component={SignUpCompanies} />
              <GuestRoute exact path={`${ROUTES.signUpCompanies}/:slug`} component={SignUpCompaniesForm} />
              <GuestRoute exact path={ROUTES.signUpOther} component={SignUpOther} />
              <GuestRoute exact path={`${ROUTES.signUpOther}/:slug`} component={SignUpOtherForm} />
              <GuestRoute exact path={ROUTES.signUpConfirmed} component={SignUpConfirmed} />
              <PrivateRoute path={`${ROUTES.profile}/:slug`} component={Profile} />
              <CaregiverRoute exact path={ROUTES.createResume} component={CreateResume} />
              <CaregiverRoute exact path={`${ROUTES.editResume}/:id`} component={ResumeEdit} />
              <PrivateRoute exact path={`${ROUTES.resumeDetail}/:id`} component={ResumeDetail} />
              <CompanyRoute exact path={ROUTES.jobChoosing} component={JobChoosing} />
              <CompanyRoute exact path={ROUTES.catalogResume} component={CatalogResume} />
              <PrivateRoute exact path={ROUTES.createJob} component={CreateJob} />
              <PrivateRoute exact path={`${ROUTES.editJob}/:id`} component={JobEdit} />
              <PrivateRoute exact path={`${ROUTES.jobDetail}/:id`} component={JobDetail} />
              <PrivateRoute exact path={`${ROUTES.paymentJob}/:id`} component={PaymentJob} />
              <CaregiverRoute exact path={ROUTES.catalogJobs} component={CatalogJobs} />

              <Route exact path={ROUTES.thankYou} component={ThankYou} />
              <Route exact path={ROUTES.membership} component={Membership} />
              <Route exact path={ROUTES.marketplace} component={Marketplace} />
              <PrivateRoute exact path={ROUTES.createAdvertisement} component={CreateAdvertisement} />
              <PrivateRoute exact path={`${ROUTES.editAdvertisement}/:id`} component={EditAdvertisement} />
              <PrivateRoute exact path={`${ROUTES.paymentMarketplace}/:id`} component={PaymentMarketplace} />
              <PrivateRoute exact path={`${ROUTES.paymentUpgrade}/:id`} component={PaymentUpgrade} />
              <PrivateRoute exact path={`${ROUTES.advertisementInfo}/:id`} component={AdvertisementDetail} />

              <Route exact path={ROUTES.forum} component={Forum} />
              <Route exact path={`${ROUTES.forumDetails}/:id`} component={ForumDetails} />
              <PrivateRoute exact path={ROUTES.createForumApplication} component={ForumApplication} />
              <PrivateRoute exact path={`${ROUTES.editForum}/:id`} component={EditForumContent} />
              <PrivateRoute exact path={`${ROUTES.paymentForum}/:id`} component={PaymentForum} />

              <Route exact path={ROUTES.careerCenter} component={CareerCenter} />
              <Route exact path={ROUTES.careerCenterInfo} component={CareerInfo} />
              <Route exact path={`${ROUTES.articles}`} component={Articles} />
              <Route exact path={`${ROUTES.article}/:id`} component={Article} />

              <Route exact path={ROUTES.events} component={Events} />
              <Route exact path={`${ROUTES.events}/:id`} component={EventDetail} />
              <Route exact path={ROUTES.educationTrainingCertification} component={EducationTrainingCertification} />
              <Route exact path={ROUTES.mdlive} component={MdLive} />
              <Route exact path={ROUTES.searchResults} component={SearchResults} />
              <PrivateRoute path={`/uploads/files/resume`} />
              <Route path={`${ROUTES.page404}`} component={Page404} />
              <Route path="*" component={UnderConstruction} />
            </Switch>
          </Suspense>

          <Notice />
          <ModalsContainer />
          <LightBoxContainer />
          <PagePreloader />
        </Fragment>
      </Router>
    );
  }
}

const ConnectedApp = connect(
  state => {
    return {
      isLoaded: state.appEnter.isLoaded,
    }
  },
  {
    initApp: AppEnterModule.init
  }
)(App);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<PagePreloader />} persistor={persistor}>
      <FacebookProvider appId={FACEBOOK_APP_ID}>
        <ConnectedApp />
      </FacebookProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
